export const sendRequest = async (action = '', method = 'GET', json = '') => {
    const tokenData = window.localStorage.getItem('auth');
    const headers = new Headers();

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    let config = {
        method: method,
        mode: 'cors',
        cache: 'no-cache',
        headers: headers
    }
    const actionName = action.substring(0, 6);

    if (tokenData && actionName !== 'public' && actionName !== 'reset') {
        const data = JSON.parse(tokenData);

        if (data.token) {
            headers.append('Authorization', 'Bearer ' + data.token);
        }
    }

    if (json) {
        config.body = JSON.stringify(json);
    }

    const response = await fetch(process.env.REACT_APP_API_URL + action, config);
    const jsonResponse = await response.json();

    if (response.status !== 201 && response.status !== 200) {
        if (jsonResponse.error) {
            throw jsonResponse.error;
        } else if (jsonResponse.detail) {
            throw jsonResponse.detail;
        } else {
            throw response.statusText;
        }
    }

    return jsonResponse;
}
