import { Card } from 'react-bootstrap';
import { t } from '../data/localization';

const Header = ({ locale = 'en' }) => {
    return (
        <header className="mb-4">
            <Card>
                <Card.Header className="py-0">
                    <a href={t('HOME_LINK', locale)}>
                        <img className="d-block my-1 img-fluid mx-auto" src="/logo.png" alt={t('EOHU_LOGO')} title={t('EOHU_LOGO')} />
                    </a>
                </Card.Header>
            </Card>
        </header>
    );
};

export default Header;
