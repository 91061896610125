import { useState } from 'react';
import { createCalendar, getCalendarDefaultState } from '../data/calendar';
import CalendarForm from '../components/calendar-form';
import { Alert } from 'react-bootstrap';
import TopNav from '../components/top-nav';
import { useNavigate } from 'react-router';

const CalendarNewRoute = () => {
    const navigate = useNavigate();
    const [showAlert, setShowAlert] = useState(false);
    const [variant, setVariant] = useState('');
    const [message, setMessage] = useState('');
    const [calendar, setCalendar] = useState(getCalendarDefaultState());
    const handleChange = (newCalendar) => setCalendar(newCalendar);
    const previousItems = [
        {
            label: 'Calendars',
            link: '/admin/calendars'
        }
    ];
    const handleError = (e) => {
        setMessage(e.toString());
        setVariant('danger');
        setShowAlert(true);
    };

    const handleSubmit = () => {
        createCalendar(calendar).then(() => {
            const state = {
                message: 'The calendar has been created.',
                variant: 'success'
            };
            navigate('/admin/calendars', { state: state });
        }).catch(e => {
            handleError(e);
        });
    };

    return (
        <>
          <TopNav activeItem="New Calendar" previousItems={previousItems} />
            <h1>New Calendar</h1>
            <Alert show={showAlert} variant={variant}>{message}</Alert>
            <CalendarForm
                calendar={calendar}
                handleStateChange={handleChange}
                handleSubmit={handleSubmit}
                errorCallback={handleError}
            />
        </>
    );
};

export default CalendarNewRoute;
