import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { getCalendars } from '../data/calendar';
import {
    BASE_URL,
    updateService,
    createService,
    getService,
    getDefaultServiceState
} from '../data/service';
import { Alert } from 'react-bootstrap';
import BaseForm from '../components/base-form';
import TopNav from '../components/top-nav';

const ServiceEditRoute = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [showAlert, setShowAlert] = useState(false);
    const [variant, setVariant] = useState('');
    const [message, setMessage] = useState('');
    const [calendars, setCalendars] = useState([]);
    const [service, setService] = useState(null);

    const handleChange = (newService) => {
        setService(newService);
    };

    const handleSubmit = () => {
        const response = id ? updateService(id, service) : createService(service);
        response.then(() => {
            const state = {
                message: 'The service has been saved.',
                variant: 'success'
            };
            navigate(BASE_URL, { state: state });
        }).catch(e => {
            setMessage(e.toString());
            setVariant('danger');
            setShowAlert(true);
        });
    };

    useEffect(() => {
        const params = {
            lanes: false,
            location: true,
            category: true
        };
        getCalendars(params).then((response) => {
            setCalendars(response.items);

            if (id) {
                getService(id).then((response) => {
                    setService(response);
                }).catch(e => {
                    setMessage(e.toString());
                    setVariant('danger');
                    setShowAlert(true);
                });
            } else {
                setService(getDefaultServiceState());
            }
        }).catch(e => {
            setMessage(e.toString());
            setVariant('danger');
            setShowAlert(true);
        });
    }, []);

    if (!service) {
        return <></>;
    }

    const renderTitle = () => {
        if (service && service.id) {
            return 'Edit ' + service.title;
        }

        return 'New Service';
    };

    const fields = [
        {
            name: 'title',
            label: 'Title',
            placeholder: '',
            type: 'text',
            required: true,
            tab: 'General'
        },
        {
            name: 'titleFr',
            label: 'Title (FR)',
            placeholder: '',
            type: 'text',
            required: true,
            tab: 'General'
        },
        {
            name: 'description',
            label: 'Description',
            placeholder: '',
            type: 'text',
            required: true,
            tab: 'General'
        },
        {
            name: 'descriptionFr',
            label: 'Description (FR)',
            placeholder: '',
            type: 'text',
            required: true,
            tab: 'General'
        },
        {
            name: 'minDaysAhead',
            label: 'Minimum number of days ahead',
            placeholder: 'Enter a number',
            type: 'number',
            required: true,
            tab: 'General'
        },
        {
            name: 'maxDaysAhead',
            label: 'Maximum number of days ahead',
            placeholder: 'Enter a number',
            type: 'number',
            required: true,
            tab: 'General'
        },
        {
            name: 'defaultDuration',
            label: 'Default duration of appointments',
            placeholder: 'Enter a number',
            type: 'number',
            required: true,
            tab: 'General'
        },
        {
            name: 'defaultSpots',
            label: 'Default number of spots',
            placeholder: 'Enter a number',
            type: 'number',
            required: true,
            tab: 'General'
        },
        {
            name: 'landingPage',
            label: 'Landing Page HTML',
            placeholder: '',
            type: 'textarea',
            required: true,
            height: 300,
            tab: 'Landing Page'
        },
        {
            name: 'landingPageFr',
            label: 'Landing Page HTML (FR)',
            placeholder: '',
            type: 'textarea',
            required: true,
            height: 300,
            tab: 'Landing Page'
        },
        {
            name: 'form',
            label: 'JSON (This is used for complex scenarios)',
            placeholder: '',
            type: 'textarea',
            height: 600,
            required: false,
            tab: 'Form'
        },
        {
            name: 'calendarSelectionHeader',
            label: 'Calendar Selection Header',
            placeholder: 'Enter the header text',
            type: 'text',
            required: true,
            tab: 'Calendars'
        },
         {
             name: 'calendarSelectionHeaderFr',
             label: 'Calendar Selection Header (FR)',
            placeholder: 'Enter the header text',
            type: 'text',
            required: true,
            tab: 'Calendars'
        },
        {
            name: 'dateSelectionHeader',
            label: 'Date Selection Header',
            placeholder: 'Enter the header text',
            type: 'text',
            required: true,
            tab: 'Calendars'
        },
        {
            name: 'dateSelectionHeaderFr',
            label: 'Date Selection Header (FR)',
            placeholder: 'Enter the header text',
            type: 'text',
            required: true,
            tab: 'Calendars'
        },
        {
            name: 'timeSelectionHeader',
            label: 'Time Selection Header',
            placeholder: 'Enter the header text',
            type: 'text',
            required: true,
            tab: 'Calendars'
        },
        {
            name: 'timeSelectionHeaderFr',
            label: 'Time Selection Header (FR)',
            placeholder: 'Enter the header text',
            type: 'text',
            required: true,
            tab: 'Calendars'
        },
        {
            name: 'calendarIds',
            label: 'Calendars',
            placeholder: 'Select one or more calendars',
            type: 'multiselect',
            required: true,
            tab: 'Calendars',
            options: calendars.map(calendar => {
                return {
                    label: <><strong>{calendar.name}</strong> - <em>{calendar.location.name}</em></>,
                    value: calendar.id
                };
            })
        },
        {
            name: 'emailContent',
            label: 'Email Field HTML',
            placeholder: '',
            type: 'textarea',
            required: false,
            height: 100,
            tab: 'Appointment Form'
        },
        {
            name: 'emailContentFr',
            label: 'Email Field HTML (FR)',
            placeholder: '',
            type: 'textarea',
            required: false,
            height: 100,
            tab: 'Appointment Form'
        },
        {
            name: 'confirmationPage',
            label: 'Confirmation Page HTML',
            placeholder: '',
            type: 'textarea',
            required: true,
            height: 300,
            tab: 'Confirmation Page'
        },
        {
            name: 'confirmationPageFr',
            label: 'Confirmation Page HTML (FR)',
            placeholder: '',
            type: 'textarea',
            required: true,
            height: 300,
            tab: 'Confirmation Page'
        },
        {
            name: 'enabled',
            label: 'Enabled',
            type: 'checkbox',
            required: false,
            tab: 'General'
        },
        {
            name: 'isPublic',
            label: 'Public (This option allows clients to select the service from the dropdown on the self-booking landing page)',
            type: 'checkbox',
            required: false,
            tab: 'General'
        },
        {
            name: 'consentRequired',
            label: 'Consent Required',
            type: 'checkbox',
            required: false,
            tab: 'Appointment Form'
        },
        {
            name: 'emailRequired',
            label: 'Email Required',
            type: 'checkbox',
            required: false,
            tab: 'Appointment Form'
        },
        {
            name: 'removePreferredLanguage',
            label: 'Remove Preferred Language',
            type: 'checkbox',
            required: false,
            tab: 'Appointment Form'
        },
        {
            name: 'removePhoneAlt',
            label: 'Remove Secondary Phone Number',
            type: 'checkbox',
            required: false,
            tab: 'Appointment Form'
        },
        {
            name: 'removePhoneDoNotCall',
            label: 'Remove Phone Do Not Call',
            type: 'checkbox',
            required: false,
            tab: 'Appointment Form'
        },
        {
            name: 'removePhoneAltDoNotCall',
            label: 'Remove Secondary Phone Do Not Call',
            type: 'checkbox',
            required: false,
            tab: 'Appointment Form'
        },
        {
            name: 'removePhoneReminderType',
            label: 'Remove Phone Reminder Type',
            type: 'checkbox',
            required: false,
            tab: 'Appointment Form'
        },
        {
            name: 'removePrintButton',
            label: 'Remove Print Button',
            type: 'checkbox',
            required: false,
            tab: 'Appointment Form'
        }
    ];

    const previousItems = [
        {
            label: 'Services',
            link: '/admin/services'
        }
    ];

    return (
        <>
            <TopNav activeItem={renderTitle()} previousItems={previousItems} />
            <h1>{renderTitle()}</h1>
            <Alert show={showAlert} variant={variant}>{message}</Alert>
            <BaseForm fields={fields}
                formState={service}
                setFormStateCallback={handleChange}
                submitButtonLabel="Save"
                validFormCallback={handleSubmit}
            />
        </>
    );
};

export default ServiceEditRoute;
