import { useEffect } from 'react';
import TimePicker from 'react-bootstrap-time-picker';
import {
    Form,
} from 'react-bootstrap';
import CalendarLaneDurations from './calendar-lane-durations';
import TimeSlotMultiPicker from './time-slot-multi-picker';
import FormField from './form-field';
import { convertSecondsToTime, convertTimeToSeconds } from '../data/utils';

const CalendarLane = ({ parentIndex, calendarLane, handleStateChange, isGroupSession = false }) => {
    const intervals = [300, 600, 900, 1800, 2400, 3600, 7200];
    const intervalField = {
        name: 'slotInterval',
        label: 'Select an interval',
        placeholder: 'Select an interval',
        type: 'select',
        required: true,
        options: intervals.map(interval => {
            return {
                value: interval,
                label: parseInt(interval) / 60 + ' minutes'
            };
        })
    };

    const slotInterval = calendarLane.slotInterval ? calendarLane.slotInterval : calendarLane.durations[0].value;
    const startTimeSeconds = convertTimeToSeconds(calendarLane.startTime);
    const endTimeSeconds = convertTimeToSeconds(calendarLane.endTime);
    const slotCount = Math.round((endTimeSeconds - startTimeSeconds) / slotInterval);
    const slots = [...Array(slotCount)].map((value, index) => {
        return convertSecondsToTime(startTimeSeconds + (index * slotInterval));
    });

    const handleChange = (property, newValue) => {
        if (property === 'startTime' || property === 'endTime') {
            if ((property === 'startTime' && newValue < convertTimeToSeconds(calendarLane.endTime)) ||
                (property === 'endTime' && newValue > convertTimeToSeconds(calendarLane.startTime))) {
                handleStateChange(calendarLane, { ...calendarLane, [property]: convertSecondsToTime(newValue) });
            }
        } else {
            handleStateChange(calendarLane, { ...calendarLane, [property]: newValue });
        }
    };

    const handleCalendarLaneDurationsChange = (newCalendarLaneDurations) => {
        let newCalendarLane = {
            ...calendarLane,
            durations: newCalendarLaneDurations.sort((a, b) => (a.value > b.value) ? 1 : -1)
        };
        if (!newCalendarLane.durations.find(item => item.isDefault)) {
            newCalendarLane.durations[0].isDefault = true;
        }
        handleStateChange(calendarLane, newCalendarLane);
    };

    useEffect(() => {
        const newBreakSlots = calendarLane.breakSlots.filter(x => slots.includes(x)).sort();
        const newBookableSlots = calendarLane.bookableSlots.filter(x => slots.includes(x)).sort();
        calendarLane.breakSlots = newBreakSlots;
        calendarLane.bookableSlots = newBookableSlots;
        handleStateChange(calendarLane, calendarLane);
    }, [calendarLane]);

    return (
        <>
            <Form.Group className="mb-3" controlId={parentIndex + "name"}>
                <Form.Label>Name*</Form.Label>
                <Form.Control required type="text" placeholder="Enter name" value={calendarLane.name} onChange={(e) => handleChange('name', e.target.value)} />
                <Form.Text className="text-muted">
                    Enter name for your calendar lane
                </Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId={parentIndex + "startTime"}>
                <Form.Label>Start time*</Form.Label>
                <TimePicker start="00:00:00" end="23:59:59" step={5} format={12} value={startTimeSeconds} onChange={time => handleChange('startTime', time)} />
                <Form.Text className="text-muted">
                    Enter start time
                </Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId={parentIndex + "endTime"}>
                <Form.Label>End time*</Form.Label>
                <TimePicker start="00:00:00" end="23:59:59" step={5} format={12} value={endTimeSeconds} onChange={time => handleChange('endTime', time)} />
                <Form.Text className="text-muted">
                    Enter end time
                </Form.Text>
            </Form.Group>
            {isGroupSession
                ? <></>
                : <>
                    <Form.Group className="mb-3">
                        <CalendarLaneDurations calendarLaneDurations={calendarLane.durations} handleStateChange={handleCalendarLaneDurationsChange} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <FormField
                            key="reason"
                            index="codes"
                            field={intervalField}
                            fieldValue={calendarLane.slotInterval}
                            readOnly={false}
                            setFieldValue={(newValue) => handleChange('slotInterval', newValue)}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <TimeSlotMultiPicker
                            title="Breaks"
                            slots={slots}
                            selectedTimeSlots={calendarLane.breakSlots}
                            handleStateUpdate={(data) => handleChange('breakSlots', data)} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <TimeSlotMultiPicker
                            title="Bookable Time Slots"
                            slots={slots.filter(time => !calendarLane.breakSlots.includes(time))}
                            selectedTimeSlots={calendarLane.bookableSlots}
                            handleStateUpdate={(data) => handleChange('bookableSlots', data)} />
                    </Form.Group>
                </>
            }
        </>
    );
};

export default CalendarLane;
