import { useState, useEffect } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import BaseList from '../components/base-list';
import Loading from '../components/loading';
import TopNav from '../components/top-nav';
import { getCalendar, getGroupSessionSeries } from '../data/calendar';

const GroupSessionSeriesListRoute = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [calendar, setCalendar] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [message, setMessage] = useState(null);
    const [variant, setVariant] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [items, setItems] = useState([]);
    const previousItems = [
        {
            label: 'Calendars',
            link: '/admin/calendars'
        }
    ];

    const fields = [
        {
            name: 'startDate',
            label: 'Start Date',
            type: 'date'
        },
        {
            name: 'endDate',
            label: 'End Date',
            type: 'date'
        },
        {
            name: 'deadline',
            label: 'Registration Deadline',
            type: 'date'
        },
        {
            name: 'spots',
            label: 'Total Number of Spots'
        },
        {
            name: 'spotsAvailable',
            label: 'Spots Available'
        },
        {
            name: 'registrationCount',
            label: 'Number of Registrations'
        },
    ];

    const actionColumns = [
        {
            label: 'Action',
            render: (item) => {
                const link = '/admin/calendars/' + id + '/group-session-series/' + item.id;
                const label = 'View Details';
                return <Button className="w-100" onClick={() => navigate(link)}>{label}</Button>;
            }
        }
    ];

    useEffect(() => {
        Promise.all([
            getCalendar(id),
            getGroupSessionSeries(id)
        ]).then(res => {
            setCalendar(res[0]);
            setItems(res[1].items);
        }).catch(e => {
            setVariant('danger');
            setMessage(e.toString());
            setShowAlert(true);
        }).finally(() => {
            setIsLoading(false);
        });
    }, []);

    if (isLoading) {
        return <Loading />;
    }

    const title = 'Group Session Series - ' + (calendar ? calendar.name : '');

    return (
        <>
          <TopNav activeItem={title} previousItems={previousItems} />
          <h1>{title}</h1>
            <Alert show={showAlert} variant={variant}>{message}</Alert>
            <BaseList fields={fields} items={items} customColumns={actionColumns} />
        </>
    );
};

export default GroupSessionSeriesListRoute;
