import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { getCategoryFields, getDefaultCategoryState, createCategory } from '../data/category';
import BaseForm from '../components/base-form';
import { Alert } from 'react-bootstrap';
import TopNav from '../components/top-nav';

const CategoryNewRoute = () => {
    const navigate = useNavigate();
    const fields = getCategoryFields().filter(item => item.showForm);
    const [showAlert, setShowAlert] = useState(false);
    const [variant, setVariant] = useState('');
    const [message, setMessage] = useState('');
    const [category, setCategory] = useState(getDefaultCategoryState());

    const handleStateChange = (newCategory) => {
        setCategory(newCategory);
    };

    const handleSubmit = () => {
        createCategory(category).then((data) => {
            const state = {
                message: 'The category has has been created.',
                variant: 'success'
            };
            navigate('/admin/categories', { state: state });
        }).catch(e => {
            setMessage(e.toString());
            setVariant('danger');
            setShowAlert(true);
        });
    };
    const previousItems = [
        {
            label: 'Categories',
            link: '/admin/categories'
        }
    ];
    return (
        <>
          <TopNav activeItem="New Category" previousItems={previousItems} />
            <h1>New Category</h1>
            <Alert show={showAlert} variant={variant}>{message}</Alert>
            <BaseForm fields={fields} formState={category} setFormStateCallback={handleStateChange} submitButtonLabel="Save Category" validFormCallback={handleSubmit} />
        </>
    );
};

export default CategoryNewRoute;
