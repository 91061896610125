import { sendRequest } from './api';

const provinces = [{ label: 'N/A', value: '' }, { label: 'Alberta', value: 'AB' }, { label: 'British Columbia', value: 'BC' }, { label: 'Manitoba', value: 'MB' }, { label: 'New Brunswick', value: 'NB' }, { label: 'Newfoundland and Labrador', value: 'NL' }, { label: 'Northwest Territories', value: 'NT' }, { label: 'Nova Scotia', value: 'NS' }, { label: 'Nunavut', value: 'NU' }, { label: 'Ontario', value: 'ON' }, { label: 'Prince Edward Island', value: 'PE' }, { label: 'Quebec', value: 'QC' }, { label: 'Saskatchewan', value: 'SK' }, { label: 'Yukon Territory', value: 'YT' }];
export const regions = [
    { label: 'All Regions', value: '', color: '#6699FF' },
    { label: 'Alexandria', value: 'Alexandria', color: '#FF9966' },
    { label: 'Casselman', value: 'Casselman', color: '#CC0033' },
    { label: 'Cornwall', value: 'Cornwall', color: '#00467F' },
    { label: 'Hawkesbury', value: 'Hawkesbury', color: '#3300CC' },
    { label: 'Rockland', value: 'Rockland', color: '#3399FF' },
    { label: 'Winchester', value: 'Winchester', color: '#CC33CC' },
    { label: 'PR', value: 'PR', color: '#CC0033' },
    { label: 'SDG', value: 'SDG', color: '#00467F' },
    { label: 'Virtual', value: 'Virtual', color: '#FF9900' },
];
export const getLocationFields = () => {
    return [
        {
            name: 'id',
            label: 'ID',
            type: 'index',
            required: false,
            showListing: false,
            showForm: false
        },
        {
            name: 'name',
            label: 'Name',
            placeholder: 'Enter the name of the location',
            type: 'text',
            required: true,
            showListing: true,
            showForm: true
        },
        {
            name: 'nameFr',
            label: 'Name (FR)',
            placeholder: 'Enter the name of the location',
            type: 'text',
            required: true,
            showListing: true,
            showForm: true
        },
        {
            name: 'officeRegion',
            label: 'Office Region',
            placeholder: 'Select the office region',
            type: 'select',
            required: true,
            showListing: true,
            showForm: true,
            options: regions
        },
        {
            name: 'isVirtual',
            label: 'Virtual',
            type: 'checkbox',
            required: false,
            showListing: true,
            showForm: true
        },
        {
            name: 'address',
            label: 'Address',
            placeholder: 'Enter the address',
            type: 'text',
            required: true,
            showListing: true,
            showForm: true,
            dependsOn: {
                field: 'isVirtual',
                value: false
            }
        },
        {
            name: 'addressFr',
            label: 'Address (FR)',
            placeholder: 'Enter the address',
            type: 'text',
            required: true,
            showListing: true,
            showForm: true,
            dependsOn: {
                field: 'isVirtual',
                value: false
            }
        },
        {
            name: 'city',
            label: 'City',
            placeholder: 'Enter the city',
            type: 'text',
            required: true,
            showListing: true,
            showForm: true,
            dependsOn: {
                field: 'isVirtual',
                value: false
            }
        },
        {
            name: 'province',
            label: 'Province',
            placeholder: 'Select an option',
            type: 'select',
            required: true,
            showListing: true,
            showForm: true,
            options: provinces,
            dependsOn: {
                field: 'isVirtual',
                value: false
            }
        },
        {
            name: 'email',
            label: 'Email',
            placeholder: 'Enter the email address',
            type: 'email',
            required: false,
            showListing: true,
            showForm: true
        },
        {
            name: 'postalCode',
            label: 'Postal Code',
            placeholder: 'Enter the postal code',
            type: 'text',
            required: true,
            showListing: true,
            showForm: true,
            dependsOn: {
                field: 'isVirtual',
                value: false
            }
        },
        {
            name: 'phone',
            label: 'Phone',
            placeholder: 'Enter the phone number',
            type: 'tel',
            required: false,
            showListing: true,
            showForm: true
        },
        {
            name: 'createdAt',
            label: 'Created At',
            placeholder: '',
            type: 'datetime',
            required: false,
            showListing: true,
            showForm: false
        },
        {
            name: 'updatedAt',
            label: 'Updated At',
            placeholder: '',
            type: 'datetime',
            required: false,
            showListing: true,
            showForm: false
        }
    ];
};


export const getDefaultLocationState = () => {
    return {
        name: '',
        officeRegion: 'Cornwall',
        address: '',
        email: '',
        city: '',
        province: 'ON',
        postalCode: '',
        phone: '',
        isVirtual: false
    };
};

export const createLocation = (location) => {
    const url = 'locations';
    const response = sendRequest(url, 'POST', location);

    return response;
};

export const getLocation = (id) => {
    const url = 'locations/' + id;
    const response = sendRequest(url);

    return response;
};

export const getLocations = () => {
    return sendRequest('locations');
}

export const deleteLocation = (id) => {
    const response = sendRequest('locations/' + id, 'DELETE');
    return response;
}

export const updateLocation = (id, location) => {
    const url = 'locations/' + id;
    const response = sendRequest(url, 'PUT', location);

    return response;
};
