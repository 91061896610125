import moment from 'moment';
import {
    Button,
    Container,
    Navbar,
    NavDropdown,
    Nav,
    Offcanvas
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const Header = ({ me }) => {
    const navigate = useNavigate();
    const basePath = '/admin';
    const navigationItems = [
        {
            name: 'Calendar Overview',
            path: basePath,
            roles: ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_ROOT']
        },
        {
            name: 'Calendars',
            path: basePath + '/calendars',
            roles: ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_ROOT']
        },
        {
            name: 'Categories',
            path: basePath + '/categories',
            roles: ['ROLE_ADMIN', 'ROLE_ROOT']
        },
        {
            name: 'Email Templates',
            path: basePath + '/templates',
            roles: ['ROLE_ROOT']
        },
        {
            name: 'Locations',
            path: basePath + '/locations',
            roles: ['ROLE_ADMIN', 'ROLE_ROOT']
        },
        {
            name: 'Data Exporter',
            path: basePath + '/export',
            roles: ['ROLE_ROOT']
        },
        {
            name: 'Report',
            path: basePath + '/report',
            roles: ['ROLE_ROOT', 'ROLE_ADMIN']
        },
        {
            name: 'Search',
            path: basePath + '/search',
            roles: ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_ROOT']
        },
        {
            name: 'Services',
            path: basePath + '/services',
            roles: ['ROLE_ROOT']
        },
        {
            name: 'Users',
            path: basePath + '/users',
            roles: ['ROLE_ADMIN', 'ROLE_ROOT']
        }
    ].filter(x => me.roles.some(y => x.roles.includes(y)));

    const getLoginMessage = () => {
        const lastLogin = moment(me.lastLoginAt).format('lll');
        return <>
            Signed In User {me.firstName} {me.lastName} (Logged in on: {lastLogin})
        </>;
    };

    const handleLogout = (e) => {
        e.preventDefault();
        window.localStorage.removeItem('auth');
        navigate('/admin/login');
    };

    return (
        <header className="d-print-none">
            <Navbar bg="light" expand={false}>
                <Container fluid>
                    <Navbar.Toggle aria-controls="offcanvasNavbar" />
                    <Navbar.Brand href="#">
                        {getLoginMessage()}
                        <Button className="mx-4" variant="primary" onClick={handleLogout}>Sign Out</Button>{' '}
                    </Navbar.Brand>
                    <Navbar.Offcanvas
                        id="offcanvasNavbar"
                        aria-labelledby="offcanvasNavbarLabel"
                        placement="start"
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id="offcanvasNavbarLabel">Main Menu</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav className="justify-content-end flex-grow-1 pe-3">
                                {navigationItems.map((item, index) => <Nav.Link key={index} href={item.path}>{item.name}</Nav.Link>).filter(item => item !== false)}
                                <NavDropdown.Divider />
                                <Nav.Link href="/admin/help">Help</Nav.Link>
                                <Nav.Link href="#" onClick={handleLogout}>Logout</Nav.Link>
                            </Nav>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
        </header>
    );
};

export default Header;
