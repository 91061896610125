import React, { useEffect, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import BaseList from '../components/base-list';
import Loading from '../components/loading';
import TopNav from '../components/top-nav';
import { formatCalendarTitle, getCalendar, getCalendarUsers, updateCalendarUsers } from '../data/calendar';
import { getUsers, canManageCalendars, roleOptions } from '../data/user';

const CalendarUsersRoute = () => {
    const { id } = useParams();
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState(null);
    const [variant, setVariant] = useState(null);
    const [calendar, setCalendar] = useState(null);
    const [calendarUsers, setCalendarUsers] = useState(null);
    const [users, setUsers] = useState(null);

    useEffect(() => {
        Promise.all([
            getCalendar(id, {
                lanes: 0,
                category: 1,
                location: 1,
                users: 1
            }),
            getCalendarUsers(id),
            getUsers()
        ]).then((res) => {
            setCalendar(res[0]);
            setCalendarUsers(res[1].items);
            setUsers(res[2].items);
        }).catch(e => {
            setMessage(e.toString());
            setVariant('danger');
            setShowAlert(true);
        });
    }, []);

    const handleUpdate = (x, enable = true) => {
        const newCalendarUsers = enable ? [...calendarUsers, x.id] : calendarUsers.filter(y => y !== x.id);

        updateCalendarUsers(id, newCalendarUsers).then(res => {
            setCalendarUsers(newCalendarUsers);
        }).catch(e => {
            setMessage(e.toString());
            setVariant('danger');
            setShowAlert(true);
        });
    };

    const fields = [
        {
            name: 'firstName',
            label: 'First Name',
            type: 'text',
        },
        {
            name: 'lastName',
            label: 'Last Name',
            type: 'text',
        },
        {
            name: 'enabled',
            label: 'Account Enabled',
            type: 'checkbox',
        }
    ];

    const customColumns = [
        {
            label: 'Role(s)',
            render: x => {
                return x.roles.map(y => roleOptions.find(z => z.value === y).label).join(',');
            }
        },
        {
            label: 'Has Access',
            render: x => {
                const canManage = canManageCalendars(x);
                const hasAccess = calendarUsers.includes(x.id);
                return hasAccess || canManage  ? 'Yes' : 'No';
            }
        },
        {
            label: 'Action',
            render: x => {
                const canManage = canManageCalendars(x);

                if (canManage) {
                    return <></>;
                }

                const hasAccess = calendarUsers.includes(x.id);
                const label = hasAccess ? 'Remove Access' : 'Grant Access';

                return (
                    <Button
                        onClick={() => hasAccess ? handleUpdate(x, false) : handleUpdate(x)}>
                        {label}
                    </Button>
                );
            }
        }
    ];

    if (!calendar || !users) {
        return <Loading />;
    }

    const previousItems = [
        {
            label: 'Calendars',
            link: '/admin/calendars'
        }
    ];

    return (
        <>
          <TopNav activeItem="Calendar Users" previousItems={previousItems} />
            <h1>Manage Users</h1>
            <h2>{formatCalendarTitle(calendar)}</h2>
            <h3>List of Users</h3>
            <Alert show={showAlert} variant={variant}>{message}</Alert>
            <BaseList
                fields={fields}
                items={users}
                customColumns={customColumns}
            />
        </>
    );
};

export default CalendarUsersRoute;
