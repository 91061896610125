import { Col, Card, Row, Button } from 'react-bootstrap';
import { formatTime } from '../data/utils';

const TimeSlotMultiPicker = ({ slots = [], selectedTimeSlots = [], handleStateUpdate, title = '' }) => {
    const handleClick = (time) => {
        if (selectedTimeSlots.includes(time)) {
            handleStateUpdate(selectedTimeSlots.filter(item => item !== time));
        } else {
            handleStateUpdate([...selectedTimeSlots, time].sort());
        }
    };

    return (
        <Card className="mb-3">
            <Card.Header>
                <h4 className="float-start pt-2">{title}</h4>
                {selectedTimeSlots.length > 0 ?
                    <Button variant="primary" className="float-end" onClick={(e) => handleStateUpdate([])}>
                        Unselect All
                    </Button> :
                    <Button variant="primary" className="float-end" onClick={(e) => handleStateUpdate(slots)}>
                        Select All
                    </Button>
                }
            </Card.Header>
            <Card.Body>
                <Row>
                    {slots.map((time, index) => {
                        const isSelected = selectedTimeSlots.includes(time);
                        return (
                            <Col sm={3} key={index}>
                                <Card style={{ cursor: 'pointer' }} text={isSelected ? 'white' : ''}
                                    bg={isSelected ? 'primary' : ''}
                                    key={index}
                                    className="mb-2 text-center"
                                    onClick={() => handleClick(time)}>
                                    <Card.Body>
                                        {formatTime(time)}
                                    </Card.Body>
                                </Card>
                            </Col>
                        );
                    })}
                </Row>
            </Card.Body>
        </Card>
    );
};

export default TimeSlotMultiPicker;
