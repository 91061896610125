export const MONTH_NAMES = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

export const MONTH_NAMES_FR = [
    "janvier", "février", "mars", "avril", "mai", "juin",
    "juillet", "août", "septembre", "octobre", "novembre", "décembre"
];

export const WEEKDAYS = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
export const PHONE_REGEX = '[0-9]{3}(-)?[0-9]{3}(-)?[0-9]{4}';

export const formatTime = (time, locale = 'en') => {
    const chunks = time.split(':');
    const hour = parseInt(chunks[0]);

    if (locale === 'fr') {
        return hour +  'h' +  (chunks[1] === '00' ? '' : chunks[1]);
    }

    const hours = ((hour + 11) % 12 + 1);
    return hours + ':' + chunks[1] + ' ' + (hour >= 12 ? 'p.m.' : 'a.m.');
}

export const formatDate = (date, locale = 'en') => {
    const chunks = date.split('-');
    const day = parseInt(chunks[2]).toString();
    const monthIndex = parseInt(chunks[1]) - 1;

    if (locale === 'fr') {
        return  day +  ' ' + MONTH_NAMES_FR[monthIndex] + ' ' + chunks[0];
    }

    return  MONTH_NAMES[monthIndex] + ' ' + day + ', ' + chunks[0];
};

export const convertTimeToSeconds = (timeString) => {
    const arr = timeString.split(":");
    const seconds = arr[0] * 3600 + arr[1] * 60 + (+arr[2]);

    return parseInt(seconds);
};

export const convertSecondsToTime = (seconds, hour = true) => {
    const intSeconds = parseInt(seconds);

    if (intSeconds < 0) {
        return "-" + convertSecondsToTime(-intSeconds);
    }

    const offset = hour ? 0 : 3;
    return new Date(intSeconds * 1000).toISOString().substr(11 + offset, 8 - offset);
};

export const displayAge = (birth, target) => {
    let months = target.diff(birth, 'months', true)
    let birthSpan = { year: Math.floor(months / 12), month: Math.floor(months) % 12, day: Math.round((months % 1) * target.daysInMonth(), 0) }
    let age = '';

    if (birthSpan.year < 1 && birthSpan.month < 1) {
        age = birthSpan.day < 1 ? 'Not born yet!' : birthSpan.day + ' day' + (birthSpan.day > 1 ? 's' : '') + ' old';
    } else if (birthSpan.year < 1) {
        age = birthSpan.month + ' month' + (birthSpan.month > 1 ? 's ' : ' ') + birthSpan.day + ' day' + (birthSpan.day > 1 ? 's' : '') + ' old';
    } else if (birthSpan.year < 2) {
        age = birthSpan.year + ' year' + (birthSpan.year > 1 ? 's ' : ' ') + birthSpan.month + ' month' + (birthSpan.month > 1 ? 's ' : '') + ' old';
    } else {
        age = birthSpan.year + ' year' + (birthSpan.year > 1 ? 's' : '') + ' old';
    }

    return age;
};

export const toUrlParams = (obj) => {
    return Object.keys(obj)
        .map(key => {
            const values = Array.isArray(obj[key]) ? obj[key] : [obj[key]];
            return values.map(value => key + "=" + value).join("&");
        })
        .join("&");
};

export const convertToISO8601 = (date) => {
    return date.toISOString().split('T')[0];
};

export const formatPhone = (input) => {
    const digits = input.replace(/\D/g, '').substring(0, 10);
    const areaCode = digits.substring(0, 3);
    const middle = digits.substring(3, 6);
    const last = digits.substring(6, 10);
    let output = '';

    if (areaCode.length > 0) {
        output = areaCode;

        if (middle.length > 0 || input.charAt(3) === '-') {
            output += '-' + middle;

            if (last.length > 0 || input.charAt(7) === '-') {
                output += '-' + last;
            }
        }
    }

    return output;
};
