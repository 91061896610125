import { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { getCalendars, formatCalendarTitle } from '../data/calendar';
import { getReport } from '../data/report';
import BaseForm from '../components/base-form';
import Loading from '../components/loading';
import TopNav from '../components/top-nav';
import BaseList from '../components/base-list';

const ReportRoute = () => {
    const [calendars, setCalendars] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState('');
    const [variant, setVariant] = useState('');
    const [formState, setFormState] = useState({
        calendarIds: [],
        startDate: null,
        endDate: null,
        minAgeMonths: 2,
        maxAgeMonths: 780
    });
    const [items, setItems] = useState(null);
    const handleSubmit = () => {
        getReport(formState).then(res => {
            setItems(res.items);
        }).catch(e => {
            setVariant('danger');
            setMessage(e.toString());
            setShowAlert(true);
        });
    };

    useEffect(() => {
        const params = {
            category: 1,
            location: 1,
            enabled: 1
        };
        getCalendars(params).then(x => {
            setCalendars(x.items);
        }).catch(e => {
            setVariant('danger');
            setMessage(e.toString());
            setShowAlert(true);
        });
    }, []);

    if (!calendars) {
        return <Loading />;
    }

    const ageOptions = [
        {
            label: '2 months',
            value: 2
        },
        {
            label: '4 months',
            value: 4
        },
        {
            label: '6 months',
            value: 6
        },
        {
            label: '12 months',
            value: 12
        },
        {
            label: '15 months',
            value: 15
        },
        {
            label: '18 months',
            value: 18
        },
        {
            label: '2 years',
            value: 24
        },
        {
            label: '4 years',
            value: 48
        },
        {
            label: '6 years',
            value: 72
        },
        {
            label: '14 years',
            value: 168
        },
        {
            label: '16 years',
            value: 192
        },
        {
            label: '24 years',
            value: 288
        },
        {
            label: '65 years',
            value: 780
        }
    ];

    const fields = [
        {
            name: 'calendarIds',
            type: 'multiselect',
            label: 'Calendars',
            placeholder: 'Select one or more calendars',
            required: true,
            options: calendars.map(x => {
                return {
                    label: formatCalendarTitle(x),
                    value: x.id
                };
            })
        },
        {
            name: 'startDate',
            type: 'date',
            label: 'Start Date',
            required: true
        },
        {
            name: 'endDate',
            type: 'date',
            label: 'End Date',
            required: true
        },
        {
            name: 'minAgeMonths',
            type: 'select',
            label: 'Minimum Age',
            required: true,
            placeholder: 'Select a value',
            options: ageOptions
        },
        {
            name: 'maxAgeMonths',
            type: 'select',
            label: 'Maximum Age',
            placeholder: 'Select a value',
            required: true,
            options: ageOptions
        }
    ];

    const listFields = [
        {
            name: 'name',
            type: 'text',
            label: 'Calendar'
        },
        {
            name: 'date',
            type: 'text',
            label: 'Appointment Date'
        },
        {
            name: 'dob',
            type: 'text',
            label: 'Date of Birth'
        }
    ];

    const handleFormChange = (x) => {
        if (x.minAgeMonths !== null && x.maxAgeMonths !== null) {
            if (parseInt(x.minAgeMonths) > parseInt(x.maxAgeMonths)) {
                return;
            }
        }

        if (x.startDate !== null && x.endDate !== null) {
            if (x.startDate > x.endDate) {
                return;
            }
        }

        setFormState(x);
    };

    return (
        <>
            <TopNav activeItem="Report" />
            <h1>Report</h1>
            <Alert show={showAlert} variant={variant}>{message}</Alert>
            <p><b>Select a value for each field below:</b></p>
            <BaseForm
                fields={fields}
                formState={formState}
                setFormStateCallback={handleFormChange}
                validFormCallback={handleSubmit} />
            <p>{items !== null && items.length > 0 ? items.length + ' records(s) found.' : <></>}</p>
            {items && listFields ? <BaseList fields={listFields} items={items} /> : <></>}
        </>
    );
};

export default ReportRoute;
