import { sendRequest } from './api';

export const getTemplateFields = () => {
    return [
        {
            name: 'id',
            label: 'ID',
            type: 'index',
            required: false,
            showListing: false,
            showForm: false
        },
        {
            name: 'name',
            label: 'Template Name',
            placeholder: 'Enter template name',
            type: 'text',
            required: true,
            showListing: true,
            showForm: true
        },
        {
            name: 'fromEmail',
            label: 'From Email',
            placeholder: 'From email',
            type: 'email',
            required: true,
            showListing: false,
            showForm: true
        },
        {
            name: 'fromName',
            label: 'From Name',
            placeholder: 'From Name',
            type: 'text',
            required: true,
            showListing: false,
            showForm: true
        },
        {
            name: 'fromNameFr',
            label: 'From Name (FR)',
            placeholder: 'From Name',
            type: 'text',
            required: true,
            showListing: false,
            showForm: true
        },
        {
            name: 'subject',
            label: 'Subject',
            placeholder: 'Enter subject line',
            type: 'text',
            required: true,
            showListing: false,
            showForm: true
        },
         {
            name: 'subjectFr',
            label: 'Subject (FR)',
            placeholder: 'Enter subject line',
            type: 'text',
            required: true,
            showListing: false,
            showForm: true
        },
        {
            name: 'body',
            label: 'Body',
            placeholder: 'Body',
            type: 'textarea',
            height: 800,
            required: true,
            showListing: false,
            showForm: true
        },
        {
            name: 'bodyFr',
            label: 'Body (FR)',
            placeholder: 'Body',
            type: 'textarea',
            height: 800,
            required: true,
            showListing: false,
            showForm: true
        },
        {
            name: 'createdAt',
            label: 'Created At',
            placeholder: '',
            type: 'datetime',
            required: false,
            showListing: true,
            showForm: false
        },
        {
            name: 'updatedAt',
            label: 'Updated At',
            placeholder: '',
            type: 'datetime',
            required: false,
            showListing: true,
            showForm: false
        }
    ];
};

export const getDefaultTemplateState = () => {
    return {
        fromEmail: 'noreply@eohu.ca',
        fromName: 'Eastern Ontario Health Unit',
        subject: 'Your appointment has been scheduled',
        body: ''
    };
};

const url = 'templates';

export const createTemplate = (template) => {
    return sendRequest(url, 'POST', template);
};

export const getTemplate = (id) => {
    return sendRequest(url + '/' + id);
};

export const getTemplates = () => {
    return sendRequest(url);
}

export const updateTemplate = (id, template) => {
    return sendRequest(url + '/' + id, 'PUT', template);
};

export const deleteTemplate = (id) => {
    return sendRequest(url + '/' + id, 'DELETE');
};
