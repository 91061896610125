import { useState } from 'react';
import { useParams } from 'react-router';
import { Container, Col, Row, Alert } from 'react-bootstrap';
import BaseForm from '../components/base-form';
import Footer from '../components/footer';
import { sendRequest } from '../data/api';

const PasswordReset = () => {
    const { code } = useParams();
    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [variant, setVariant] = useState();
    const [message, setMessage] = useState();
    const [success, setSuccess] = useState(false);
    const [formState, setFormState] = useState({ code: code, password: null, passwordCheck: null });
    const safeRegex = '(?=^.{9,}$)(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^A-Za-z0-9]).*';
    const fields = [{
        name: 'password',
        label: 'Password',
        placeholder: 'Enter your password',
        type: 'password',
        pattern: safeRegex,
        required: true
    },
    {
        name: 'passwordCheck',
        label: 'Password Validation',
        placeholder: 'Enter your password again',
        type: 'password',
        pattern: safeRegex,
        required: true
    }];

    const handleSubmitEvent = () => {
        if (!loading) {
            if (formState.password !== formState.passwordCheck) {
                setMessage('The passwords must match!');
                setVariant('warning');
                setShowAlert(true);
            } else {
                setShowAlert(false);
                setLoading(true);
                sendRequest(
                    'newpassword',
                    'POST',
                    formState
                ).then(() => {
                    setLoading(false);
                    setSuccess(true);
                }).catch(e => {
                    setLoading(false);

                    if (e.toString() === 'INVALID_CODE') {
                        setMessage('The password reset code is invalid.');
                    } else {
                        setMessage(e.toString());
                    }

                    setShowAlert(true);
                    setVariant('danger');
                });
            }
        }
    };

    const getSubmitButtonLabel = () => {
        if (loading) {
            return 'Please wait';
        }

        return 'Reset Password';
    };

    return (
        <>
            <main role="main">
                <Container fluid>
                    <Row className="d-flex flex-column min-vh-100 justify-content-center align-items-center login-form-container">
                        <Col lg="4">
                            <h1 className="text-center">Reset your password</h1>
                            <Alert className="my-4 text-center" show={showAlert} variant={variant}>{message}</Alert>
                            {success ?
                                <p className="text-center">Your password has been reset. Click <a href="/admin/login">here</a> to log in.</p>
                                :
                                <>
                                    <p>Your password must:</p>
                                    <ul>
                                        <li>contain an uppercase character</li>
                                        <li>contain a lowercase character</li>
                                        <li>contain a non-alphanumeric character</li>
                                        <li>contain a digit</li>
                                        <li>have at least 8 characters</li>
                                    </ul>
                                    <BaseForm
                                        enableRecaptcha={true}
                                        fields={fields}
                                        formState={formState}
                                        setFormStateCallback={x => setFormState(x)}
                                        submitButtonLabel={getSubmitButtonLabel()}
                                        validFormCallback={handleSubmitEvent} />
                                </>}
                        </Col>
                    </Row>
                </Container>
            </main>
            <Footer />
        </>
    );
};

export default PasswordReset;
