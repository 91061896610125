import { useState, useEffect } from 'react';
import { useNavigate, useParams, useOutletContext } from 'react-router';
import { getCalendars } from '../data/calendar';
import { getCategories } from '../data/category';
import { getLocations } from '../data/location';
import { getUser, getDefaultUserState, updateUser, getUserFields, canManageCalendars, createUser } from '../data/user';
import BaseForm from '../components/base-form';
import Loading from '../components/loading';
import TopNav from '../components/top-nav';
import { Alert } from 'react-bootstrap';

const UserRoute = () => {
    const { id } = useParams();
    const me = useOutletContext();
    const navigate = useNavigate();
    const [showAlert, setShowAlert] = useState(false);
    const [variant, setVariant] = useState('');
    const [message, setMessage] = useState('');
    const [user, setUser] = useState(null);
    const [fields, setFields] = useState([]);
    const [options, setOptions] = useState(null);

    const handleSubmit = () => {
        const response = (id ? updateUser(id, user) : createUser(user));

        response.then((data) => {
            if (data.error) {
                setMessage(data.error.message);
                setVariant('danger');
                setShowAlert(true);
            } else {
                const state = {
                    message: 'The user has been saved.',
                    variant: 'success'
                };
                navigate('/admin/users', { state: state });
            }
        });
    };

    useEffect(() => {
        Promise.all([
            (id ? getUser(id) : getDefaultUserState()),
            getCalendars({
                enabled: 1,
                lanes: 0,
                location: 0,
                category: 0
            }),
            getCategories(),
            getLocations()
        ]).then(res => {
            setUser(res[0]);
            setOptions({
                calendars: res[1].items,
                categories: res[2].items,
                locations: res[3].items
            });
        }).catch(e => {
            setVariant('danger');
            setMessage(e.toString());
            setShowAlert(true);
        });
    }, []);

    useEffect(() => {
        if (user && options) {
            const userFields = getUserFields(
                options.categories,
                options.locations,
                options.calendars.filter(x => {
                    return (
                        (user.categoryIds.length < 1 || user.categoryIds.includes(String(x.categoryId))) &&
                            (user.locationIds.length < 1 || user.locationIds.includes(String(x.locationId)))
                    );
                })
            )
                .filter(x => x.showForm)
                .filter(x => me.roles.some(y => x.roles.includes(y)));
            if (canManageCalendars(user)) {
                setFields(userFields.filter(x => x.tab !== 'Calendar Access'));
                setMessage('The user has access to all calendars.');
                setVariant('info');
                setShowAlert(true);
            } else {
                setMessage('');
                setShowAlert(false);
                if (options) {
                    setFields(userFields);
                }
            }
        }
    }, [user, options]);

    const previousItems = [
        {
            label: 'Users',
            link: '/admin/users'
        }
    ];

    return (
        <>
            <TopNav activeItem={user ? 'Edit User' : 'New User'} previousItems={previousItems} />
            <h1>{id && user ? user.firstName + ' ' + user.lastName : 'New User'}</h1>
            <Alert show={showAlert} variant={variant}>{message}</Alert>
            {user
                ? <BaseForm fields={fields}
                    formState={user}
                    setFormStateCallback={x => setUser(x)}
                    submitButtonLabel="Save User"
                    validFormCallback={handleSubmit} />
                : !showAlert ? <Loading /> : ''}
        </>
    );
};

export default UserRoute;
