import { ProgressBar } from 'react-bootstrap';

const Capacity = ({ value = 0 }) => {
    let variant = '';
    if (value >= 80) {
        variant = 'danger';
    } else if (value >= 50) {
        variant = 'warning';
    } else {
        variant = 'success';
    }
    return <ProgressBar className="my-1" variant={variant} now={value} label={`${value}%`} />;
};

export default Capacity;
