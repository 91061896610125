import { Alert, Button, Card, Col, Row } from "react-bootstrap";
import BaseForm from "../components/base-form";
import Loading from '../components/loading';
import { useNavigate, useOutletContext } from "react-router";
import { useEffect, useState } from "react";
import { getServices } from '../data/public';
import { t } from '../data/localization';

export const FrontendServiceListRoute = () => {
    const locale = useOutletContext();
    const navigate = useNavigate();
    const [selectedService, setSelectedService] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [variant, setVariant] = useState('warning');
    const [message, setMessage] = useState('');
    const [services, setServices] = useState(null);

    useEffect(() => {
        getServices(locale).then((response) => {
            setServices(response.items);
        }).catch(e => {
            setMessage(e.toString());
            setVariant('danger');
            setShowAlert(true);
        });
    }, []);

    if (!services) {
        return <Loading />;
    }

    const handleServiceClick = (service) => {
        setSelectedService(service);
        window.scrollTo({
            top: document.body.scrollHeight,
            left: 0,
            behavior: 'smooth',
        });
    };

    const handleSubmit = () => {
        return navigate('/' + locale + '/service/' + selectedService.id);
    };

    const field = {
        name: 'selectedService',
        type: 'custom',
        element: <Row>
            {services.map((service, index) => {
                const isSelected = (selectedService !== null && selectedService === service);
                return (
                    <Col key={index} sm={12}>
                        <Card bg="light" className="mb-3" role="button" border={isSelected ? 'primary' : ''} onClick={() => handleServiceClick(service)}>
                            <Card.Body>
                                <Card.Title className="text-center">{service.title}</Card.Title>
                                <Card.Text className="text-center">
                                    <em>{service.description}</em>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                );
            })}
        </Row>
    };

    return (
        <>
            <h1 className="text-center">{t('Appointment Booking and Course Registration', locale)}</h1>
            <Alert className="text-center" show={showAlert} variant={variant}>{message}</Alert>
            <p><strong>{t('Select a service and click next:', locale)}</strong></p>
            {services.length > 0 ? <BaseForm
                fields={[field]}
                formState={{ selectedService: selectedService }}
                validFormCallback={handleSubmit}
                submitButtonLabel={t('Next', locale)} />
                : <p>{t('There are no services currently available.', locale)}</p>}
        </>
    );
};

export default FrontendServiceListRoute;
