import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { getLocationFields, getDefaultLocationState, createLocation } from '../data/location';
import BaseForm from '../components/base-form';
import { Alert } from 'react-bootstrap';
import TopNav from '../components/top-nav';

const LocationNewRoute = () => {
    const navigate = useNavigate();
    const fields = getLocationFields().filter(item => item.showForm);
    const [showAlert, setShowAlert] = useState(false);
    const [variant, setVariant] = useState('');
    const [message, setMessage] = useState('');
    const [location, setLocation] = useState(getDefaultLocationState());

    const handleStateChange = (newLocation) => {
        setLocation(newLocation);
    };

    const handleSubmit = () => {
        createLocation(location).then(data => {
            const state = {
                message: 'The location has has been created.',
                variant: 'success'
            };
            navigate('/admin/locations', { state: state });
        }).catch(e => {
            setMessage(e.toString());
            setVariant('danger');
            setShowAlert(true);
        });
    };

    const previousItems = [
        {
            label: 'Locations',
            link: '/admin/locations'
        }
    ];


    return (
        <>
          <TopNav activeItem="Edit Location" previousItems={previousItems} />
            <h1>New Location</h1>
            <Alert show={showAlert} variant={variant}>{message}</Alert>
            <BaseForm fields={fields} formState={location} setFormStateCallback={handleStateChange} submitButtonLabel="Save Location" validFormCallback={handleSubmit} />
        </>
    );
};

export default LocationNewRoute;
