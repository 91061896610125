import { useOutletContext } from "react-router";
import { t } from "../data/localization";
import BaseForm from "./base-form";

const FrontendServiceStepAppointment = ({ header, appointmentFields, appointment, handleAppointmentChange, handleSubmit }) => {
    const locale = useOutletContext();
    return (
        <>
            <p><strong>{t('Please fill all required fields below (fields marked by an * are mandatory and must be completed).', locale)}</strong></p>
            {header}
            <BaseForm
                enableRecaptcha={true}
                fields={appointmentFields}
                formState={appointment}
                setFormStateCallback={handleAppointmentChange}
                submitButtonLabel={t('Proceed with Booking', locale)}
                validFormCallback={handleSubmit}
            />
        </>
    );
};

export default FrontendServiceStepAppointment;
