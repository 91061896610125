import { useState } from 'react';
import { Container, Col, Row, Alert } from 'react-bootstrap';
import BaseForm from '../components/base-form';
import Footer from '../components/footer';
import { sendRequest } from '../data/api';

const PasswordResetRequest = () => {
    const [loading, setLoading] = useState(false);
    const [formSent, setFormSent] = useState(false);
    const [variant, setVariant] = useState('');
    const [message, setMessage] = useState('');
    const [formState, setFormState] = useState({ code: null });
    const fields = [{
        name: 'username',
        label: 'Email',
        placeholder: 'Enter your email address.',
        type: 'email',
        required: true
    }];

    const handleSubmitEvent = () => {
        if (!loading) {
            setLoading(true);
            sendRequest(
                'reset',
                'POST',
                formState
            ).then(() => {
                setVariant('info');
                setMessage('A new password will be sent to the email if the user account exists. Please check your inbox.');
                setFormSent(true);
            }).catch(e => {
                setMessage(e.toString());
                setVariant('danger');
            }).finally(() => {
                setLoading(false);
            });
        }
    };

    return (
        <>
            <main role="main">
                <Container fluid>
                    <Row className="d-flex flex-column min-vh-100 justify-content-center align-items-center login-form-container">
                        <Col lg="4">
                            <h1 className="text-center">Password Reset</h1>
                            <Alert className="my-4 text-center" show={message !== ''} variant={variant}>{message}</Alert>
                            {!formSent
                                ? <BaseForm
                                    enableRecaptcha={false}
                                    fields={fields}
                                    formState={formState}
                                    setFormStateCallback={x => setFormState(x)}
                                    submitButtonLabel={loading ? 'Please Wait' : 'Submit'}
                                    validFormCallback={handleSubmitEvent} />
                                : ''}
                        </Col>
                    </Row>
                </Container>
            </main>
            <Footer />
        </>
    );
};

export default PasswordResetRequest;
