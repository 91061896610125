import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import { getCalendars } from '../data/calendar';
import { createExport } from '../data/export';
import BaseForm from '../components/base-form';
import Loading from '../components/loading';
import TopNav from '../components/top-nav';

const DataExporterRoute = () => {
    const navigate = useNavigate();
    const [calendars, setCalendars] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState('');
    const [variant, setVariant] = useState('');
    const [formState, setFormState] = useState({ startDate: null, endDate: null });

    const fetchResults = () => {
        createExport(formState).then(data => {
            window.open("data:application/zip;base64," + data);
            setMessage('The archive has been generated.');
            setVariant('success');
            setShowAlert(true);
        }).catch(e => {
            setVariant('danger');
            setMessage(e.toString());
            setShowAlert(true);
        });
    };

    useEffect(() => {
        const params = {
            category: 1,
            location: 1,
            enabled: 1
        };
        getCalendars(params).then(x => {
            setCalendars(x.items);
        }).catch(e => {
            setVariant('danger');
            setMessage(e.toString());
            setShowAlert(true);
        });
    }, []);

    if (!calendars) {
        return <Loading />;
    }

    const fields = [
        {
            name: 'startDate',
            type: 'date',
            label: 'Start Date',
            required: true
        },
        {
            name: 'endDate',
            type: 'date',
            label: 'End Date',
            required: true
        },
    ];

    return (
        <>
            <TopNav activeItem="Data Exporter" />
            <h1>Data Exporter</h1>
            <Alert show={showAlert} variant={variant}>{message}</Alert>
            <BaseForm
                fields={fields}
                formState={formState}
                setFormStateCallback={x => setFormState(x)}
                validFormCallback={fetchResults} />
        </>
    );
};

export default DataExporterRoute;
