import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Alert, Button, Form } from 'react-bootstrap';
import CalendarLanes from '../components/calendar-lanes';
import {
    getCalendar,
    getCalendarDate,
    updateCalendarDate
} from '../data/calendar';
import TopNav from '../components/top-nav';
import { formatDate } from '../data/utils';
import Loading from '../components/loading';
import BaseForm from '../components/base-form';

const CalendarDateEditRoute = () => {
    const { id, date } = useParams();
    const navigate = useNavigate();
    const [validated, setValidated] = useState(false);
    const [calendar, setCalendar] = useState(null);
    const [calendarDate, setCalendarDate] = useState(null);
    const [fields, setFields] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState('');
    const [variant, setVariant] = useState('');
    const previousItems = [
        {
            label: 'Calendars',
            link: '/admin/calendars'
        },
        {
            label: 'Calendar Dates',
            link: '/admin/calendars/' + id + '/dates'
        }
    ];

    const handleSubmit = () => {
        updateCalendarDate(id, date, calendarDate).then(data => {
            const state = {
                message: 'The calendar date has been updated.',
                variant: 'success'
            };
            navigate('/admin/calendars/' + id + '/dates', { state: state });
        }).catch(e => {
            setMessage(e.toString());
            setVariant('danger');
            setShowAlert(true);
        });
    };

    const handleStateChange = (newState) => {
        setCalendarDate(newState);
    };

    useEffect(() => {
        Promise.all([
            getCalendar(id),
            getCalendarDate(id, date)
        ]).then(res => {
            setCalendar(res[0]);
            setCalendarDate(res[1]);
        }).catch(e => {
            setVariant(variant);
            setMessage(e.toString());
            setShowAlert(true);
        });
    }, []);

    useEffect(() => {
        if (calendar && calendarDate) {
            setFields([
                {
                    name: 'enabled',
                    label: 'Enabled',
                    placeholder: 'Enabled',
                    type: 'checkbox',
                    required: false
                },
                {
                    name: 'lanes',
                    label: '',
                    placeholder: '',
                    type: 'custom',
                    required: true,
                    element: <CalendarLanes
                        calendarLanes={calendarDate.lanes}
                        handleStateChange={(data) => setCalendarDate({ ...calendarDate, lanes: data })}
                        isGroupSession={calendar.groupSession}
                    />
                }
            ]);
        }
    }, [calendar, calendarDate]);

    return (
        <>
            <TopNav activeItem="Edit Calendar Date" previousItems={previousItems} />
            <Alert show={showAlert} variant={variant}>{message}</Alert>
            {calendar && calendarDate ?
                <h1>Edit Calendar - {calendar.name} - {formatDate(calendarDate.date)}</h1> : <Loading />}
            {fields && calendarDate ? <BaseForm fields={fields}
                formState={calendarDate}
                setFormStateCallback={handleStateChange}
                submitButtonLabel="Save Calendar Date"
                validFormCallback={handleSubmit} /> : <Loading />}
        </>
    );
};

export default CalendarDateEditRoute;
