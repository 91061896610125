import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { getTemplateFields, getDefaultTemplateState, createTemplate } from '../data/template';
import BaseForm from '../components/base-form';
import { Alert } from 'react-bootstrap';
import TopNav from '../components/top-nav';

const TemplateNewRoute = () => {
    const navigate = useNavigate();
    const fields = getTemplateFields().filter(item => item.showForm);
    const [showAlert, setShowAlert] = useState(false);
    const [variant, setVariant] = useState('');
    const [message, setMessage] = useState('');
    const [template, setTemplate] = useState(getDefaultTemplateState());

    const handleStateChange = (newTemplate) => {
        setTemplate(newTemplate);
    };

    const handleSubmit = () => {
        const response = createTemplate(template);
        response.then((data) => {
            if (data.error) {
                setMessage(data.error.message);
                setVariant('danger');
                setShowAlert(true);
            } else {
                const state = {
                    message: 'The template has has been created.',
                    variant: 'success'
                };
                navigate('/admin/templates', { state: state });
            }
        });
    };
    const previousItems = [
        {
            label: 'Templates',
            link: '/admin/templates'
        }
    ];
    return (
        <>
            <TopNav activeItem="New Template" previousItems={previousItems} />
            <h1>New Template</h1>
            <Alert show={showAlert} variant={variant}>{message}</Alert>
            <BaseForm fields={fields} formState={template} setFormStateCallback={handleStateChange} submitButtonLabel="Save Template" validFormCallback={handleSubmit} />
        </>
    );
};

export default TemplateNewRoute;
