import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import BaseList from '../components/base-list';
import { canManageCalendars, getUserFields, getUsers, hasSystemRole } from '../data/user';
import TopNav from '../components/top-nav';

const UserListRoute = () => {
    const me = useOutletContext();
    const navigate = useNavigate();
    const { state } = useLocation();
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState('');
    const [variant, setVariant] = useState('');
    const [items, setItems] = useState([]);
    const fields = getUserFields().filter(item => item.showListing);

    const handleEdit = (item) => {
        navigate('/admin/users/' + item['id'] + '/edit');
    };

    useEffect(() => {
        getUsers()
            .then(data => {
                const newItems = data.items.map(x => {
                    if (!hasSystemRole(me) && canManageCalendars(x)) {
                        return {...x, id: null};
                    } else {
                        return x;
                    }
                });
                setItems(newItems);
            }).catch(e => {
                setMessage(e.toString());
                setVariant('danger');
                setShowAlert(true);
            });
        if (state) {
            setMessage(state.message);
            setVariant(state.variant);
            setShowAlert(true);
            setTimeout(() => setShowAlert(false), 5000);
        }
    }, []);

    return (
        <>
            <TopNav activeItem="Users" />
            <h1>Users</h1>
            <Alert show={showAlert} variant={variant}>{message}</Alert>
            {variant !== 'danger' ? <BaseList fields={fields} items={items} handleEdit={handleEdit} /> : ''}
        </>
    );
};

export default UserListRoute;
