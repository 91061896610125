import { useState, useEffect } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import BaseList from '../components/base-list';
import Loading from '../components/loading';
import TopNav from '../components/top-nav';
import { deleteAppointment } from '../data/appointment';
import { getCalendar, getGroupSessionSeries } from '../data/calendar';
import { formatDate } from '../data/utils';

const GroupSessionSeriesRoute = () => {
    const navigate = useNavigate();
    const { id, calendarDateId } = useParams();
    const [calendar, setCalendar] = useState(null);
    const [seriesData, setSeriesData] = useState(null);
    const [message, setMessage] = useState(null);
    const [variant, setVariant] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const fields = [
        {
            name: 'date',
            label: 'Date',
            type: 'date'
        },
        {
            name: 'time',
            label: 'Time',
            type: 'time'
        },
        {
            name: 'duration',
            label: 'Duration',
            type: 'duration'
        },
    ];

    const registrationFields = [
        {
            name: 'clients',
            label: 'Client(s)'
        },
        {
            name: 'phoneNumbers',
            label: 'Phone Number(s)'
        },
        {
            name: 'email',
            label: 'Email'
        },
        {
            name: 'spots',
            label: 'Spot(s)'
        },
        {
            name: 'registeredOn',
            label: 'Registered On',
            type: 'date'
        },
    ];

    const deleteRegistration = (registration) => {
        deleteAppointment(registration.id).then(() => {
            getGroupSessionSeries(id, calendarDateId).then(res => {
                setSeriesData(res);
                setVariant('success');
                setMessage('The client has been unregistered.');
                setShowAlert(true);
            }).catch(e => {
                setVariant('danger');
                setMessage(e.toString());
                setShowAlert(true);
            });
        }).catch(e => {
            setVariant('danger');
            setMessage(e.toString());
            setShowAlert(true);
        });
    };

    useEffect(() => {
        Promise.all([
            getCalendar(id),
            getGroupSessionSeries(id, calendarDateId)
        ]).then(res => {
            setCalendar(res[0]);
            setSeriesData(res[1]);
        }).catch(e => {
            setVariant('danger');
            setMessage(e.toString());
            setShowAlert(true);
        });
    }, []);

    if (calendar === null) {
        return <Loading />;
    }

    let title = '';

    if (seriesData !== null && seriesData.sessionDates.length > 0) {
        title = formatDate(seriesData.sessionDates[0].date);
        title += ' to ' + formatDate(seriesData.sessionDates[seriesData.sessionDates.length - 1].date);
    }

    const previousItems = [
        {
            label: 'Calendars',
            link: '/admin/calendars'
        },
        {
            label: 'Group Session Series - ' + calendar.name,
            link: '/admin/calendars/' + id + '/group-session-series'
        }
    ];

    return (
        <>
            <TopNav activeItem={title} previousItems={previousItems} />
            <h1>{title}</h1>
            <Alert show={showAlert} variant={variant}>{message}</Alert>
            <h2>Session Dates</h2>
            {seriesData !== null ? <BaseList fields={fields} items={seriesData.sessionDates} /> : <></>}
            <h2>Registrations</h2>
            {seriesData !== null
                ? <BaseList
                    fields={registrationFields}
                    items={seriesData.registrations}
                    handleDelete={deleteRegistration}
                    deleteButtonLabel="Unregister From Series"
                    deleteConfirmMessage="Are you sure you want to unregister this client?" />
                : <></>}
        </>
    );
};

export default GroupSessionSeriesRoute;
