import { sendRequest } from './api';
import { convertSecondsToTime, convertTimeToSeconds } from './utils';

/**
 * Lock a specific time slot.
 */
export const createLock = (time, calendarDateLaneId, duration) => {
    const endTimeSeconds = convertTimeToSeconds(time) + parseInt(duration) - 1;
    const endTime = convertSecondsToTime(endTimeSeconds);
    const data = {
        calendarDateLaneId: calendarDateLaneId,
        startTime: time,
        endTime: endTime
    };

    return sendRequest('locks', 'POST', data);
};
