import { Button, Card, Col, Table } from "react-bootstrap";
import { useOutletContext } from "react-router";
import { t } from "../data/localization";
import { formatDate, formatTime } from "../data/utils";
import { useCallback, useState } from "react";

const FrontendServiceStepSession = ({ header, groupSessions, selectedGroupSession, setSelectedGroupSession }) => {
    const [shownSchedules, setShownSchedules] = useState([]);
    const locale = useOutletContext();
    const handleClick = useCallback((groupSession) => {
        setSelectedGroupSession(groupSession);
        window.scrollTo({
            top: document.body.scrollHeight,
            left: 0,
            behavior: 'smooth',
        });
    }, [groupSessions]);

    const handleScheduleClick = useCallback((index) => {
        if (shownSchedules.includes(index)) {
            setShownSchedules(shownSchedules.filter(f => f !== index));
        } else {
            setShownSchedules([...shownSchedules, index]);
        }
    }, [groupSessions]);

    return (
        <>
            <p><strong>{header}</strong></p>
            {groupSessions.length > 0 ?
                groupSessions.map((groupSession, key) => {
                    const isSelected = (selectedGroupSession !== null && selectedGroupSession === groupSession);
                    const isScheduleShown = shownSchedules.includes(key);
                    return (
                        <Col key={key} sm={12} className="mb-2">
                            <Card bg="light" role="button" border={isSelected ? 'primary' : ''}>
                                <Card.Body>
                                    <Card.Title className="d-flex justify-content-between align-items-center">
                                      {t('Starting', locale)} {formatDate(groupSession.sessionDates[0].date, locale)}
                                      <br/>{formatTime(groupSession.time, locale)} - {formatTime(groupSession.endTime, locale)} ({t('Duration', locale)} {(parseInt(groupSession.duration) / 60)} minutes)
                                        <Button variant="primary" size="md" onClick={() => handleScheduleClick(key)}>
                                            {t('View', locale)}
                                        </Button>
                                    </Card.Title>
                                    {isScheduleShown ?
                                        <>
                                            <Table striped bordered>
                                                <thead>
                                                    <tr>
                                                      <th>{t('Date', locale)}</th>
                                                      <th>{t('Time', locale)}</th>
                                                      <th>{t('Duration', locale)}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {groupSession.sessionDates.map((date, key) =>
                                                        <tr key={key}>
                                                          <td>{formatDate(date.date, locale)}</td>
                                                          <td>{formatTime(date.time, locale)}</td>
                                                            <td>{(parseInt(date.duration) / 60)} minutes</td>
                                                        </tr>)}
                                                </tbody>
                                            </Table>
                                          {groupSession.isLocked ? <p><em>{t('Another person is currently registering for this course. Please try again in 15 minutes.', locale)}</em></p> : ''}
                                          <Button variant="primary" size="md" disabled={groupSession.isLocked} className="float-end" onClick={() => handleClick(groupSession)}>
                                                {t('Select', locale)}
                                            </Button>
                                        </>
                                        : ''}
                                </Card.Body>
                            </Card>
                        </Col>
                    );
                })
                : <p>{t('There are no dates available.', locale)}</p>}
        </>
    );

};

export default FrontendServiceStepSession;
