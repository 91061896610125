import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { updateCalendar, getCalendar } from '../data/calendar';
import CalendarForm from '../components/calendar-form';
import { Alert } from 'react-bootstrap';
import Loading from '../components/loading';
import TopNav from '../components/top-nav';

const CalendarEditRoute = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [showAlert, setShowAlert] = useState(false);
    const [variant, setVariant] = useState('');
    const [message, setMessage] = useState('');
    const [calendar, setCalendar] = useState(null);
    const handleChange = (newCalendar) => setCalendar(newCalendar);
    const previousItems = [
        {
            label: 'Calendars',
            link: '/admin/calendars'
        }
    ];
    const handleError = (e) => {
        setMessage(e.toString());
        setVariant('danger');
        setShowAlert(true);
    };

    const handleSubmit = () => {
        const response = updateCalendar(id, calendar);
        response.then(() => {
            const state = {
                message: 'The calendar has been saved.',
                variant: 'success'
            };
            navigate('/admin/calendars', { state: state });
        }).catch(e => {
            handleError(e);
        });
    };

    useEffect(() => {
        const params = {
            lanes: true,
            location: true,
            category: true
        };
        getCalendar(id, params).then(response => {
            setCalendar(response);
        }).catch(e => {
            handleError(e);
        });
    }, []);

    if (!calendar && variant !== 'error') {
        return <Loading />;
    }

    return (
        <>
          <TopNav activeItem="Edit Calendar" previousItems={previousItems} />
            <h1>Edit Calendar</h1>
            <h2>{calendar.name}</h2>
            <Alert show={showAlert} variant={variant}>{message}</Alert>
            <CalendarForm
                calendar={calendar}
                handleStateChange={handleChange}
                handleSubmit={handleSubmit}
                errorCallback={handleError}
            />
        </>
    );
};

export default CalendarEditRoute;
