import { useEffect } from 'react';
import {
    Button,
    Card,
    Tabs,
    Tab
} from 'react-bootstrap';
import { getDefaultLaneState } from '../data/calendar';
import CalendarLane from './calendar-lane';

const CalendarLanes = ({ calendarLanes = [], handleStateChange, isGroupSession = false }) => {
    const handleAdd = () => {
        const newCalendarLanes = [...calendarLanes, getDefaultLaneState()];
        handleStateChange(newCalendarLanes);
    };

    const handleDelete = (calendarLane) => {
        handleStateChange(calendarLanes.filter(lane =>
            lane !== calendarLane
        ));
    };

    const handleLaneUpdate = (oldLane, newLane) => {
        const newCalendarLanes = calendarLanes.map(item => {
            return item === oldLane ? newLane : item;
        });
        handleStateChange(newCalendarLanes);
    };

    useEffect(() => {
        /* we want to have at least one lane */
        if (calendarLanes.length < 1) {
            handleAdd();
        }
    }, []);

    return (
        <Card className="mb-3">
            <Card.Header>
                <h4 className="float-start pt-2">Calendar Lanes</h4>
                {isGroupSession ? <></> :
                    <Button className="float-end" onClick={handleAdd}>
                        Add Lane
                    </Button>
                }
            </Card.Header>
            <Card.Body>
                <Tabs justify defaultActiveKey="lane-0">
                    {calendarLanes.map((calendarLane, index) =>
                        <Tab key={index} eventKey={"lane-" + index} title={calendarLane.name}>
                            <CalendarLane
                                parentIndex={index}
                                calendarLane={calendarLane}
                                handleStateChange={handleLaneUpdate}
                                isGroupSession={isGroupSession}
                            />
                            {calendarLanes.length > 1 ?
                                <Button variant="danger" className="float-end" onClick={() => handleDelete(calendarLane)}>
                                    Delete Lane
                                </Button> : ''}
                        </Tab>
                    )}
                </Tabs>
            </Card.Body>
        </Card>
    );
};

export default CalendarLanes;
