import { Button, Col, Row } from "react-bootstrap";
import { useOutletContext } from "react-router";
import { t } from "../data/localization";
import { formatDate, formatTime } from "../data/utils";
import { Parser } from "html-to-react";

const FrontendServiceStepConfirmation = ({
    confirmationPage,
    removePrintButton,
    confirmationCode,
    selectedCalendar,
    selectedCalendarDate,
    selectedTimeslot,
    appointment
}) => {
    const locale = useOutletContext();
    const handlePrintButtonClick = () => {
        window.print();
    };

    const template = confirmationPage
        .replace('[CODE]', confirmationCode.toUpperCase())
        .replace('[DATE]', formatDate(selectedCalendarDate.date, locale))
        .replace('[TIME]', formatTime(selectedTimeslot.time, locale))
        .replace(
            '[LOCATION]',
            selectedCalendar.location.address + ', ' +
            selectedCalendar.location.city + ', ' +
            selectedCalendar.location.postalCode
        )
        .replace('[CLIENTS]', appointment.clientData.map(_ => _.preferredName ? _.firstName + ' ' + _.lastName + ' (' + _.preferredName + ')' : _.firstName + ' ' + _.lastName).join(', '));

    return (
        <Row>
            <Col>
                <img className="d-block my-2 img-fluid mx-auto d-print-none" src="/success-checkmark.png" title="success" height="25%" />
                {Parser().parse(template)}
                {removePrintButton ? <></> :
                    <Button variant="primary" className="w-100 d-print-none" size="lg" onClick={handlePrintButtonClick}>{t('Print', locale)}</Button>}
            </Col>
        </Row>
    );
};

export default FrontendServiceStepConfirmation;
