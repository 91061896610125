import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { getCategoryFields, getCategory, updateCategory } from '../data/category';
import BaseForm from '../components/base-form';
import { Alert } from 'react-bootstrap';
import TopNav from '../components/top-nav';

const CategoryNewRoute = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const fields = getCategoryFields().filter(item => item.showForm);
    const [showAlert, setShowAlert] = useState(false);
    const [variant, setVariant] = useState('');
    const [message, setMessage] = useState('');
    const [category, setCategory] = useState();

    const handleStateChange = (newCategory) => {
        setCategory(newCategory);
    };

    const handleSubmit = () => {
        updateCategory(id, category).then(data => {
            const state = {
                message: 'The category has has been updated.',
                variant: 'success'
            };
            navigate('/admin/categories', { state: state });
        }).catch(e => {
            setMessage(e.toString());
            setVariant('danger');
            setShowAlert(true);
        });
    };

    useEffect(() => {
        const response = getCategory(id);
        response.then(data => setCategory(data));
    }, []);

    if (!category) {
        return <>Please wait...</>;
    }

    const previousItems = [
        {
            label: 'Categories',
            link: '/admin/categories'
        }
    ];

    return (
        <>
            <TopNav activeItem="Edit Category" previousItems={previousItems} />
            <h1>Edit Category</h1>
            <Alert show={showAlert} variant={variant}>{message}</Alert>
            <BaseForm fields={fields} formState={category} setFormStateCallback={handleStateChange} submitButtonLabel="Save Category" validFormCallback={handleSubmit} />
        </>
    );
};

export default CategoryNewRoute;
