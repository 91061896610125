import { sendRequest } from './api';
export const roleOptions = [
    {
        label: 'System Administrator',
        value: 'ROLE_ROOT'
    },
    {
        label: 'Calendar Administrator',
        value: 'ROLE_ADMIN'
    },
    {
        label: 'Frontend Administrator',
        value: 'ROLE_FRONTEND'
    },
    {
        label: 'Basic User',
        value: 'ROLE_USER'
    },
];

export const getUserFields = (categories = [], locations = [], calendars = []) => {
    const fields = [
        {
            name: 'id',
            tab: 'General',
            label: 'ID',
            type: 'text',
            required: false,
            showListing: false,
            showForm: false,
            isPrimaryKey: true,
            roles: ['ROLE_ROOT']
        },
        {
            name: 'firstName',
            tab: 'General',
            label: 'First Name',
            placeholder: 'Enter first name',
            type: 'text',
            required: true,
            showListing: true,
            showForm: true,
            roles: ['ROLE_ROOT', 'ROLE_ADMIN']
        },
        {
            name: 'lastName',
            tab: 'General',
            label: 'Last Name',
            placeholder: 'Enter last name',
            type: 'text',
            required: true,
            showListing: true,
            showForm: true,
            roles: ['ROLE_ROOT', 'ROLE_ADMIN']
        },
        {
            name: 'email',
            tab: 'General',
            label: 'Email',
            placeholder: 'Enter email',
            type: 'email',
            required: true,
            showListing: true,
            showForm: true,
            roles: ['ROLE_ROOT', 'ROLE_ADMIN']
        },
        {
            name: 'enabled',
            tab: 'General',
            label: 'Enabled',
            placeholder: 'Enabled',
            type: 'checkbox',
            required: false,
            showListing: true,
            showForm: true,
            roles: ['ROLE_ROOT', 'ROLE_ADMIN']
        },
        {
            name: 'roles',
            tab: 'General',
            label: 'Roles',
            placeholder: 'Select one or multiple roles',
            type: 'multiselect',
            required: true,
            options: roleOptions,
            showForm: true,
            showListing: true,
            roles: ['ROLE_ROOT']
        },
        {
            name: 'categoryIds',
            tab: 'Calendar Access',
            label: 'Categories',
            placeholder: 'Select one or more categories',
            type: 'multiselect-dropdown',
            required: false,
            options: categories.map(x => {
                return {
                    value: x.id,
                    label: x.name
                };
            }),
            showSelectToggle: false,
            showForm: true,
            showListing: false,
            roles: ['ROLE_ADMIN', 'ROLE_ROOT']
        },
        {
            name: 'locationIds',
            tab: 'Calendar Access',
            label: 'Locations',
            placeholder: 'Select one or more locations',
            type: 'multiselect-dropdown',
            required: false,
            options: locations.map(x => {
                return {
                    value: x.id,
                    label: x.name
                };
            }),
            showForm: true,
            showListing: false,
            roles: ['ROLE_ADMIN', 'ROLE_ROOT']
        },
        {
            name: 'calendarIds',
            tab: 'Calendar Access',
            label: 'Calendars',
            placeholder: 'Select calendars',
            type: 'multiselect',
            showSelectToggle: false,
            required: true,
            options: calendars.map(x => {
                const locationName = locations.find(y => y.id == x.locationId).name;
                return {
                    value: x.id,
                    label: <><span><b>{x.name}</b><em> - {locationName}</em></span></>
                };
            }),
            showForm: true,
            showListing: false,
            roles: ['ROLE_ADMIN', 'ROLE_ROOT']
        },
        {
            name: 'createdAt',
            label: 'Created At',
            placeholder: '',
            type: 'datetime',
            required: false,
            showListing: true,
            showForm: false,
            roles: ['ROLE_ADMIN', 'ROLE_ROOT']
        },
        {
            name: 'updatedAt',
            label: 'Updated At',
            placeholder: '',
            type: 'datetime',
            required: false,
            showListing: true,
            showForm: false,
            roles: ['ROLE_ROOT', 'ROLE_ROOT']
        }
    ]

    return fields;
};

export const getDefaultUserState = () => {
    return {
        name: '',
        enabled: true,
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        roles: ['ROLE_USER'],
        calendarIds: [],
        categoryIds: [],
        locationIds: []
    }
};

export const getUser = (id) => {
    const url = 'users/' + id;
    const response = sendRequest(url);

    return response;
};

export const getUsers = () => {
    return sendRequest('users');
};

export const createUser = (user) => {
    const url = 'users';
    const response = sendRequest(url, 'POST', user);

    return response;
};

export const deleteUser = (id) => {
    return sendRequest('users/' + id, 'DELETE');
};

export const updateUser = (id, user) => {
    const url = 'users/' + id;
    const response = sendRequest(url, 'PUT', user);

    return response;
};

export const canManageCalendars = (user) => {
    return user.roles.some(x => x === 'ROLE_ADMIN' || x === 'ROLE_ROOT');
};

export const hasSystemRole = (user) => {
    return user.roles.some(x => x === 'ROLE_ROOT');
};
