import { useEffect, useState } from 'react';
import { useNavigate, useLocation, useOutletContext } from 'react-router-dom';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import BaseList from '../components/base-list';
import { formatCalendarTitle, getCalendars } from '../data/calendar';
import { formatDate } from '../data/utils';
import BaseForm from '../components/base-form';
import { getAppointments } from '../data/appointment';
import Loading from '../components/loading';
import TopNav from '../components/top-nav';

const SearchRoute = () => {
    const { state } = useLocation();
    const defaultFilterState = { calendarId: state, query: '' };
    const navigate = useNavigate();
    const [calendars, setCalendars] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState('');
    const [variant, setVariant] = useState('');
    const [items, setItems] = useState(null);
    const [limit, setLimit] = useState(100);
    const [sortField, setSortField] = useState('createdAt');
    const [sortOrder, setSortOrder] = useState('DESC');
    const [filter, setFilter] = useState(defaultFilterState);
    const previousItems = [
        {
            label: 'Calendars',
            link: '/admin/calendars'
        }
    ];

    const fetchResults = (skipFilter = false) => {
        const params = {
            sortField: sortField,
            sortOrder: sortOrder,
            limit: limit,
            enabled: 1,
            query: skipFilter ? '' : filter.query,
            calendarId: skipFilter ? '' : filter.calendarId
        };

        getAppointments(params).then(data => {
            setItems(data.items);
        }).catch(e => {
            setVariant('danger');
            setMessage(e.toString());
            setShowAlert(true);
        });
    };

    const handleReset = () => {
        setFilter(defaultFilterState);
        setItems(null);
    };

    const customSubmit = (
        <Row className="my-3">
            <Col xs={6}>
                <Button variant="primary" className="w-100" type="submit" size="lg">
                    Submit
                </Button>
            </Col>
            {filter ?
                <Col xs={6}>
                    <Button variant="primary" className="w-100" type="button" onClick={handleReset} size="lg">
                        Reset
                    </Button>
                </Col> : ''}
        </Row>
    );

    const customColumns = [
        {
            label: 'Action',
            render: (item) => {
                let url = '';

                if (item.groupSession) {
                    const calendarDateId = item.parentDateId ? item.parentDateId : item.calendarDateId;
                    url = '/admin/calendars/' + item.calendarId + '/group-session-series/' + calendarDateId;
                } else {
                    const params = {
                        id: item.calendarId,
                        date: item.date,
                        appointmentId: item.id
                    };

                    url = '/admin/booking?' + new window.URLSearchParams(params).toString();
                }

                return <Button onClick={() => navigate(url)}>View</Button>;
            }
        }
    ];

    useEffect(() => {
        const params = {
            category: 1,
            location: 1,
            enabled: 1
        };

        getCalendars(params).then(x => {
            setCalendars(x.items);
        }).catch(e => {
            setVariant('danger');
            setMessage(e.toString());
            setShowAlert(true);
        });
    }, []);


    if (!calendars) {
        return <Loading />;
    }

    const listFields = [
        {
            name: 'confirmationCode',
            label: 'Confirmation Code',
            type: 'text',
            filterable: true
        },
        {
            name: 'calendarId',
            label: 'Calendar',
            type: 'text',
            postHook: (value) => {
                const calendar = calendars.find(x => x.id === value);

                if (calendar) {
                    return formatCalendarTitle(calendar);
                }

                return 'N/A';
            }
        },
        {
            name: 'clientData',
            label: 'Client(s)',
            type: 'text',
            filterable: true,
            postHook: (value) => {
                return value.map(x => {
                    return x.firstName + ' ' + x.lastName + (x.preferredName ? ' (' + x.preferredName + ')' : '');
                }).join(', ');
            }
        },
        {
            name: 'date',
            label: 'Date',
            type: 'text',
            postHook: (value) => {
                return formatDate(value);
            }
        },
        {
            name: 'startTime',
            label: 'Time',
            type: 'time'
        }
    ];

    const filterFields = [
        {
            name: 'calendarId',
            type: 'select',
            label: 'Calendar',
            options: calendars.map(x => {
                return {
                    label: formatCalendarTitle(x),
                    value: x.id
                };
            })
        },
        {
            name: 'query',
            type: 'text',
            label: 'Query',
            placeholder: 'Enter one or multiple keywords'
        }
    ];

    return (
        <>
            <TopNav activeItem="Search" previousItems={previousItems} />
            <h1>Search</h1>
            <Alert show={showAlert} variant={variant}>{message}</Alert>
            <BaseForm
                fields={filterFields}
                formState={filter}
                setFormStateCallback={x => setFilter(x)}
                validFormCallback={fetchResults}
                customSubmitElement={customSubmit} />
            <p>{items !== null && items.length > 0 ? items.length + ' result(s).' : <></>}</p>
            {items ? <BaseList fields={listFields} items={items} customColumns={customColumns} /> : <></>}
        </>
    );
};

export default SearchRoute;
