import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { getTemplate, updateTemplate, getTemplateFields } from '../data/template';
import BaseForm from '../components/base-form';
import Loading from '../components/loading';
import { Alert } from 'react-bootstrap';
import TopNav from '../components/top-nav';

const TemplateEditRoute = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const fields = getTemplateFields(true).filter(item => item.showForm);

    const [showAlert, setShowAlert] = useState(false);
    const [variant, setVariant] = useState('');
    const [message, setMessage] = useState('');
    const [template, setTemplate] = useState(null);

    const handleStateChange = (newTemplate) => {
        setTemplate(newTemplate);
    };

    const handleSubmit = () => {
        updateTemplate(id, template).then((data) => {
            const state = {
                message: 'The template has been updated.',
                variant: 'success'
            };
            navigate('/admin/templates', { state: state });
        }).catch(e => {
            setMessage(e.toString());
            setVariant('danger');
            setShowAlert(true);
        });
    };

    useEffect(() => {
        getTemplate(id).then(
            data => setTemplate(data)
        ).catch(e => {
            setMessage(e.toString());
            setVariant('danger');
            setShowAlert(true);
        });
    }, []);

    const previousItems = [
        {
            label: 'Templates',
            link: '/admin/templates'
        }
    ];

    return (
        <>
            <TopNav activeItem="New Template" previousItems={previousItems} />
            <h1>Edit Template</h1>
            <Alert show={showAlert} variant={variant}>{message}</Alert>
            {template ? <BaseForm fields={fields} formState={template} setFormStateCallback={handleStateChange} submitButtonLabel="Save Template" validFormCallback={handleSubmit} /> : <Loading />}
        </>
    );
};

export default TemplateEditRoute;
