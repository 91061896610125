import { Card, Col } from "react-bootstrap";
import { useOutletContext } from "react-router";
import { formatTime } from "../data/utils";

const FrontendServiceStepTimeslot = ({ header, timeslots, selectedTimeslot, setSelectedTimeslot }) => {
    const locale = useOutletContext();
    const handleTimeslotClick = (timeslot) => {
        setSelectedTimeslot(timeslot);
        window.scrollTo({
            top: document.body.scrollHeight,
            left: 0,
            behavior: 'smooth',
        });
    };

    return (
        <>
            <p><strong>{header}</strong></p>
            {timeslots.map((timeslot, index) => {
                const isSelected = (selectedTimeslot !== null && selectedTimeslot === timeslot);
                return (
                    <Col key={index}>
                        <Card bg="light" role="button" border={isSelected ? 'primary' : ''} className="mb-2" onClick={(e) => handleTimeslotClick(timeslot)}>
                            <Card.Body>
                                <Card.Title className="text-center">{formatTime(timeslot.time, locale)}</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                );
            })}
        </>
    );
};

export default FrontendServiceStepTimeslot;
