import { useState } from 'react';
import { t } from '../data/localization';
import { useNavigate, useOutletContext } from 'react-router-dom';
import BaseForm from '../components/base-form';

const FrontendConsentFormRoute = () => {
    const locale = useOutletContext();
    const navigate = useNavigate();
    const [state, setState] = useState({ consent: false });
    const fields = [
        {
            type: 'custom',
            element: <>
                <p className="my-4">{t('LANDING_1', locale)}</p>
                <p>{t('LANDING_2', locale)}</p>
            </>
        },
        {
            name: 'consent',
            label: <strong>{t('LANDING_CONSENT', locale)}</strong>,
            placeholder: '',
            type: 'checkbox',
            required: true
        }
    ];

    return (
        <>
            <h1 className="text-center">{t('Appointment Booking and Course Registration', locale)}</h1>
            <BaseForm
                fields={fields}
                state={state}
                setFormStateCallback={(newState) => setState(newState)}
                submitButtonLabel={t('Next', locale)}
                validFormCallback={() => navigate('/' + locale + '/service')} />
        </>);
};

export default FrontendConsentFormRoute;
