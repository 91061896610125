import { useEffect, useState } from 'react';
import { useNavigate, useLocation, useOutletContext } from 'react-router-dom';
import { Alert, Button } from 'react-bootstrap';
import BaseList from '../components/base-list';
import { hasSystemRole } from '../data/user';
import { getLocationFields, deleteLocation, getLocations } from '../data/location';
import TopNav from '../components/top-nav';

const LocationListRoute = () => {
    const me = useOutletContext();
    const { state } = useLocation();
    const navigate = useNavigate();
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState('');
    const [variant, setVariant] = useState('');
    const [items, setItems] = useState([]);
    const fields = getLocationFields().filter(item => item.showListing);

    const handleEdit = (item) => {
        navigate('/admin/locations/' + item['id'] + '/edit');
    };

    const handleDelete = (itemToDelete) => {
        const id = itemToDelete['id'];
        deleteLocation(id).then(data => {
            setVariant('success');
            setMessage('The location has been deleted.');
            setShowAlert(true);
            const newItems = items.filter(item => itemToDelete !== item);
            setItems(newItems);
        }).catch(e => {
            setVariant('danger');
            setMessage('The location could not be deleted. Make sure the location is not currently in use.');
            setShowAlert(true);
        });
    };

    useEffect(() => {
        getLocations()
            .then(data => setItems(data.items))
            .catch(e => {
                setVariant('danger');
                setMessage(e.toString());
                setShowAlert(true);
            });
        if (state) {
            setMessage(state.message);
            setVariant(state.variant);
            setShowAlert(true);
            setTimeout(() => setShowAlert(false), 5000);
        }
    }, []);

    return (
        <>
            <TopNav activeItem="Locations" />
            <h1>Locations</h1>
            <Alert show={showAlert} variant={variant}>{message}</Alert>
            {variant !== 'danger' ? <BaseList fields={fields} items={items} handleEdit={handleEdit} handleDelete={handleDelete} /> : ''}
            {hasSystemRole(me) ? <Button onClick={() => navigate('/admin/locations/new')}>Add New Location</Button> : ''}
        </>
    );
};

export default LocationListRoute;
