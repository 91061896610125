import { Card, Col } from "react-bootstrap";

const FrontendServiceStepCalendar = ({ header, calendars, selectedCalendar, setSelectedCalendar }) => {
    const handleCalendarClick = (calendar) => {
        setSelectedCalendar(calendar);
        window.scrollTo({
            top: document.body.scrollHeight,
            left: 0,
            behavior: 'smooth',
        });
    };

    return (
        <>
          <p><strong>{header}</strong></p>
            {calendars.map((calendar, index) => {
                const isSelected = (selectedCalendar !== null && selectedCalendar === calendar);
                return (
                    <Col key={index} sm={12}>
                        <Card bg="light" className="mb-3" role="button" border={isSelected ? 'primary' : ''} onClick={() => handleCalendarClick(calendar)}>
                            <Card.Body>
                                <Card.Title>{calendar.location.name}</Card.Title>
                                {!calendar.location.isVirtual
                                    ? <Card.Text>
                                        {calendar.location.address}, {calendar.location.city}
                                    </Card.Text> : ''}
                            </Card.Body>
                        </Card>
                    </Col>
                );
            })}
        </>
    );
};

export default FrontendServiceStepCalendar;
