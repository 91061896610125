import { useEffect, useState } from 'react';
import { Parser } from 'html-to-react';
import {
    createAppointment,
    createLock,
    getCalendarDates,
    getService,
    deleteLock,
    getGroupSessions,
    getDefaultAppointmentState,
} from '../data/public';
import { getAppointmentFields } from '../data/public';
import { Alert, Button, Col, Modal, Row } from 'react-bootstrap';
import Loading from '../components/loading';
import { convertSecondsToTime, formatDate, formatTime } from '../data/utils';
import { t } from '../data/localization';
import { useOutletContext, useParams } from 'react-router';
import FrontendServiceStepCalendar from '../components/frontend-service-step-calendar';
import FrontendServiceStepDate from '../components/frontend-service-step-date';
import FrontendServiceStepTimeslot from '../components/frontend-service-step-timeslot';
import FrontendServiceStepAppointment from '../components/frontend-service-step-appointment';
import FrontendServiceStepSession from '../components/frontend-service-step-session';
import FrontendServiceStepConfirmation from '../components/frontend-service-step-confirmation';
import FrontendServiceStepConfirmationSession from '../components/frontend-service-step-confirmation-session';
import FrontendServiceStepForm from '../components/frontend-service-step-form';

const FrontendServiceRoute = () => {
    const { id } = useParams();
    const locale = useOutletContext();
    const [showAlert, setShowAlert] = useState(false);
    const [variant, setVariant] = useState('warning');
    const [message, setMessage] = useState('');
    const [step, setStep] = useState('landing');
    const [calendarDateFilters, setCalendarDateFilters] = useState(null);
    const [groupSessionFilters, setGroupSessionFilters] = useState(null);
    const [service, setService] = useState(null);
    const [selectedCalendar, setSelectedCalendar] = useState(null);
    const [calendarDates, setCalendarDates] = useState(null);
    const [groupSessions, setGroupSessions] = useState(null);
    const [selectedCalendarDate, setSelectedCalendarDate] = useState(null);
    const [selectedTimeslot, setSelectedTimeslot] = useState(null);
    const [selectedGroupSession, setSelectedGroupSession] = useState(null);
    const [appointment, setAppointment] = useState(null);
    const [appointmentFields, setAppointmentFields] = useState(null);
    const [confirmationCode, setConfirmationCode] = useState(null);
    const [clientCount, setClientCount] = useState(1);
    const [lockedAt, setLockedAt] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [intervalId, setIntervalId] = useState(null);
    const [previousStep, setPreviousStep] = useState(null);
    const [nextStep, setNextStep] = useState(null);

    useEffect(() => {
        getService(id, locale).then((res) => {
            setService(res);
            setCalendarDateFilters({
                duration: res.defaultDuration
            });
            setGroupSessionFilters({
                spots: res.defaultSpots
            });
        }).catch(e => {
            setMessage(e.toString());
            setVariant('danger');
            setShowAlert(true);
        });
    }, []);

    useEffect(() => {
        if (selectedCalendar !== null) {
            if (selectedCalendar.groupSession) {
                setNextStep('session');
            } else {
                setNextStep('date');
            }
        }
    }, [selectedCalendar]);

    useEffect(() => {
        if (selectedCalendarDate !== null) {
            setNextStep('timeslot');
        }
    }, [selectedCalendarDate]);

    useEffect(() => {
        if (selectedTimeslot !== null) {
            setNextStep('appointment');
        }
    }, [selectedTimeslot]);

    useEffect(() => {
        if (selectedGroupSession !== null) {
            setNextStep('appointment');
        }
    }, selectedGroupSession);

    useEffect(() => {
        if (showModal) {
            deleteLock(appointment.lockToken);
        }
    }, [showModal]);

    useEffect(() => {
        setShowAlert(false);
        setNextStep(null);

        switch (step) {
            case 'landing':
                setSelectedCalendar(null);
                setSelectedCalendarDate(null);
                setSelectedTimeslot(null);
                setAppointment(null);
                setPreviousStep(null);
                setNextStep('form');
                break;
            case 'form':
                if (service !== null && service.form.length > 2) {
                    setSelectedCalendar(null);
                    setSelectedCalendarDate(null);
                    setSelectedTimeslot(null);
                    setAppointment(null);
                    setPreviousStep(null);
                } else {
                    setStep('calendar');
                }
                break;
            case 'calendar':
                setSelectedCalendar(null);
                setSelectedCalendarDate(null);
                setSelectedTimeslot(null);
                setAppointment(null);

                if (service !== null && service.form.length > 2) {
                    setPreviousStep('form');
                } else {
                    setPreviousStep('landing');
                }
                break;
            case 'date':
                setSelectedCalendarDate(null);
                setSelectedGroupSession(null);
                setSelectedTimeslot(null);
                setAppointment(null);
                setPreviousStep('calendar');
                getCalendarDates(
                    id,
                    selectedCalendar.id,
                    calendarDateFilters
                ).then(res => {
                    setCalendarDates(res.items);
                }).catch(e => {
                    setMessage(e.toString());
                    setVariant('danger');
                    setShowAlert(true);
                });
                break;
            case 'session':
                setSelectedGroupSession(null);
                setAppointment(null);
                setPreviousStep('calendar');
                getGroupSessions(
                    id,
                    selectedCalendar.id,
                    groupSessionFilters
                ).then(res => {
                    setGroupSessions(res.items);
                }).catch(e => {
                    setMessage(e.toString());
                    setVariant('danger');
                    setShowAlert(true);
                });
                break;
            case 'timeslot':
                setAppointment(null);
                setSelectedTimeslot(null);
                setPreviousStep('date');
                break;
            case 'appointment':
                const time = selectedCalendar.groupSession ? selectedGroupSession.time : selectedTimeslot.time;
                const calendarDateLaneId = selectedCalendar.groupSession ? selectedGroupSession.id : selectedTimeslot.id;
                const duration = selectedCalendar.groupSession ? selectedGroupSession.duration : calendarDateFilters.duration;

                createLock(time, calendarDateLaneId, duration).then(res => {
                    setLockedAt(new Date().getTime());
                    const spots = selectedCalendar.groupSession ? groupSessionFilters.spots : 1;
                    const data = getDefaultAppointmentState(
                        calendarDateLaneId,
                        time,
                        duration,
                        clientCount,
                        spots,
                        res.token,
                        locale
                    );
                    setAppointment({ ...data, ...[] });
                    setPreviousStep(null);
                }).catch(e => {
                    setMessage(e.toString());
                    setVariant('danger');
                    setShowAlert(true);
                });
                break;
            case 'confirmation':
                clearInterval(intervalId);
                break;
            case 'error':
                clearInterval(intervalId);
                break;
            default:
                setSelectedCalendar(null);
                setSelectedCalendarDate(null);
                setSelectedTimeslot(null);
                setAppointment(null);
                break;
        }
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'instant',
        });
    }, [step]);

    useEffect(() => {
        if (lockedAt && selectedCalendar) {
            let interval = setInterval(() => {
                const secondsElapsed = parseInt(Math.abs((new Date().getTime() - lockedAt) / 1000));
                const secondsLeft = (selectedCalendar.lockTime - secondsElapsed);
                if (secondsLeft <= 0) {
                    clearInterval(interval);
                    setShowModal(true);
                } else {
                    const timeLeft = convertSecondsToTime(secondsLeft, false);
                    setMessage(t('Time Left:', locale) + ' ' + timeLeft);

                    if (variant !== 'info' || !showAlert) {
                        setVariant('info');
                        setShowAlert(true);
                    }
                }
            }, 1000);
            setIntervalId(interval);
        }
    }, [lockedAt]);

    useEffect(() => {
        if (appointment) {
            const newAppointmentFields = getAppointmentFields(
                service,
                selectedCalendar.fields,
                appointment,
                selectedCalendar.emailsEnabled,
                locale
            );
            setAppointmentFields(newAppointmentFields);
        }
    }, [appointment]);

    if (service === null) {
        return <Loading />;
    }

    const handleRestartClick = () => {
        setShowModal(false);
        setStep('landing');
    };

    const handleAppointmentChange = (data) => {
        const endTime = parseInt(data.startTime) + parseInt(data.duration);
        setAppointment({
            ...data,
            endTime: endTime
        });
    };

    const handleSubmit = () => {
        const data = {
            ...appointment,
            startTime: convertSecondsToTime(appointment.startTime),
            endTime: convertSecondsToTime(appointment.endTime)
        };

        createAppointment(data).then(res => {
            setConfirmationCode(res.confirmationCode);
            setStep('confirmation');
            deleteLock(appointment.lockToken);
        }).catch(() => {
            setStep('error');
            setVariant('danger');
            setMessage(t('An error occurred. Please try again', locale));
            setShowAlert(true);
        });
    };

    const renderRestartButton = () => {
        return <Button variant="primary" size="lg" onClick={handleRestartClick}>{t('Restart', locale)}</Button>;
    };

    const renderStep = () => {
        let stepComponent = <></>;

        if (step === 'form' && service.form.length > 2) {
            stepComponent = <FrontendServiceStepForm
                form={JSON.parse(service.form)}
                calendarDateFilters={calendarDateFilters}
                setCalendarDateFilters={setCalendarDateFilters}
                setClientCount={setClientCount}
                callback={() => setNextStep('calendar')} />;
        } else if (step === 'calendar') {
            stepComponent = <FrontendServiceStepCalendar
                header={service.calendarSelectionHeader}
                calendars={service.calendars}
                selectedCalendar={selectedCalendar}
                setSelectedCalendar={setSelectedCalendar} />;
        } else if (step === 'date' && calendarDates !== null) {
            stepComponent = <FrontendServiceStepDate
                header={service.dateSelectionHeader}
                calendarDates={calendarDates}
                selectedCalendarDate={selectedCalendarDate}
                setSelectedCalendarDate={setSelectedCalendarDate} />;
        } else if (step === 'session' && groupSessions !== null) {
            stepComponent = <FrontendServiceStepSession
                header={service.dateSelectionHeader}
                groupSessions={groupSessions}
                selectedGroupSession={selectedGroupSession}
                setSelectedGroupSession={setSelectedGroupSession} />;
        } else if (step === 'timeslot') {
            stepComponent = <FrontendServiceStepTimeslot
                header={service.timeSelectionHeader}
                timeslots={selectedCalendarDate.timeslots}
                selectedTimeslot={selectedTimeslot}
                setSelectedTimeslot={setSelectedTimeslot} />;
        } else if (step === 'appointment' && appointment && appointmentFields) {
            let header = '';

            if (selectedCalendar.groupSession) {
                header = <p>{t('Session Date(s):', locale)}
                    <ul>
                      {selectedGroupSession.sessionDates.map(date => <li>{formatDate(date.date, locale)} {t('at', locale)} {formatTime(date.time, locale)}</li>)}
                    </ul>
                </p>;
            } else {
                header = <p>{t('Date and Time:', locale)} <strong>{formatDate(selectedCalendarDate.date, locale)} {t('at', locale)} {formatTime(convertSecondsToTime(appointment.startTime), locale)}</strong></p>;
            }

            stepComponent = <FrontendServiceStepAppointment
                header={header}
                appointmentFields={appointmentFields}
                appointment={appointment}
                handleAppointmentChange={handleAppointmentChange}
                handleSubmit={handleSubmit} />;
        } else if (step === 'confirmation') {
            if (selectedCalendar.groupSession) {
                stepComponent = <FrontendServiceStepConfirmationSession
                    confirmationPage={service.confirmationPage}
                    confirmationCode={confirmationCode}
                    location={selectedCalendar.location}
                    groupSessionDates={selectedGroupSession.sessionDates}
                    appointment={appointment} />;

            } else {
                stepComponent = <FrontendServiceStepConfirmation
                    confirmationPage={service.confirmationPage}
                    removePrintButton={service.removePrintButton}
                    confirmationCode={confirmationCode}
                    selectedCalendar={selectedCalendar}
                    selectedCalendarDate={selectedCalendarDate}
                    selectedTimeslot={selectedTimeslot}
                    appointment={appointment} />;
            }
        } else if (step === 'landing') {
            stepComponent = <>{Parser().parse(service.landingPage)}</>;
        }

        return stepComponent;
    };

    return (
        <>
            <h1 className="text-center">{service.title}</h1>
            <Alert className="text-center d-print-none" show={showAlert} variant={variant}>{message}</Alert>
            <Row className={'py-3 step-container-' + step}>
                <Row>
                    <Col>
                        {renderStep()}
                    </Col>
                </Row>
                <Row>
                    <Col xs={6}>
                        {previousStep ? <Button variant="primary" className="w-100" onClick={() => setStep(previousStep)} type="button" size="lg">
                            {t('Back', locale)}
                        </Button> : ''}
                    </Col>
                    <Col xs={6}>
                        {nextStep ? <Button variant="primary" className="w-100" onClick={() => setStep(nextStep)} type="button" size="lg">
                            {t('Next', locale)}
                        </Button> : ''}
                    </Col>
                </Row>
            </Row>
            <Modal
                show={showModal}
                onHide={handleRestartClick}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t('Session Expired', locale)}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {t('Your session has expired. Click restart to book a new appointment.', locale)}
                </Modal.Body>
                <Modal.Footer>
                    {renderRestartButton()}
                </Modal.Footer>
            </Modal>
        </>
    );
};
export default FrontendServiceRoute;
