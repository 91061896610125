import { useEffect, useState } from 'react';
import AvailableFields from './available-fields';
import CalendarLanes from './calendar-lanes';
import BaseForm from './base-form';
import Loading from './loading';
import { getCategories } from '../data/category';
import { getLocations } from '../data/location';
import { getTemplates } from '../data/template';

const CalendarForm = ({ calendar, handleStateChange, handleSubmit, errorCallback }) => {
    const [options, setOptions] = useState(null);

    useEffect(() => {
        Promise.all([
            getCategories(),
            getLocations(),
            getTemplates(),
        ]).then((res) => {
            const newOptions = {};
            newOptions.categories = res[0].items.map(category => {
                return { label: category.name, value: category.id };
            });
            newOptions.locations = res[1].items.map(location => {
                return { label: location.name, value: location.id };
            });
            newOptions.templates = res[2].items.map(template => {
                return { label: template.name, value: template.id };
            });
            setOptions(newOptions);
        }).catch(e => {
            errorCallback(e);
        });
    }, []);

    if (!options || !calendar) {
        return <Loading />;
    }
    
    const fields = [
        {
            name: 'name',
            label: 'Name',
            placeholder: 'Enter a name',
            type: 'text',
            required: true,
            tab: 'General'
        },
        {
            name: 'enabled',
            label: 'Enabled',
            placeholder: '',
            type: 'checkbox',
            tab: 'General'
        },
        {
            name: 'syncEnabled',
            label: 'Sync With Dashboard of Events',
            placeholder: '',
            type: 'checkbox',
            tab: 'General'
        },
        {
            name: 'showOnDashboard',
            label: 'Show on Calendar Overview Page',
            placeholder: '',
            type: 'checkbox',
            tab: 'General'
        },
        {
            name: 'groupSession',
            label: 'Group Sessions',
            placeholder: '',
            type: 'checkbox',
            tab: 'General'
        },
        {
            name: 'spots',
            label: 'Default Number of Spots',
            type: 'number',
            required: true,
            min: 1,
            tab: 'General',
            dependsOn: {
                field: 'groupSession',
                value: true
            }
        },
        {
            name: 'allowMultipleClients',
            label: 'Allow Multiple Clients Per Appointment',
            placeholder: '',
            type: 'checkbox',
            tab: 'General'
        },
        {
            name: 'locationId',
            label: 'Location',
            placeholder: 'Select a location',
            type: 'select',
            required: true,
            options: options.locations,
            tab: 'General'
        },
        {
            name: 'categoryId',
            label: 'Category',
            placeholder: 'Select a category',
            type: 'select',
            required: true,
            options: options.categories,
            tab: 'General'
        },
        {
            name: 'lockTime',
            label: 'Lock Timeout Value',
            type: 'number',
            required: true,
            min: 300,
            tab: 'General'
        },
        {
            name: 'fields',
            label: 'Fields',
            placeholder: 'Fields',
            type: 'custom',
            required: true,
            element: <AvailableFields
                availableFields={calendar.fields}
                handleStateChange={(data) => handleStateChange({ ...calendar, fields: data })}
            />,
            tab: 'Fields'
        },
        {
            name: 'lanes',
            label: '',
            placeholder: '',
            type: 'custom',
            required: true,
            element: <CalendarLanes
                       isGroupSession={calendar.groupSession}
                       calendarLanes={calendar.lanes}
                       handleStateChange={(data) => handleStateChange({ ...calendar, lanes: data })}
                     />,
            tab: 'Schedule'
        },
        {
            name: 'emailsEnabled',
            label: 'Emails Enabled',
            placeholder: '',
            type: 'checkbox',
            required: false,
            options: options.templates,
            tab: 'Emails'
        },
        {
            name: 'notificationTemplateId',
            label: 'Notification Email Template',
            placeholder: '',
            type: 'select',
            required: true,
            dependsOn: {
                field: 'emailsEnabled',
                value: true
            },
            options: options.templates,
            tab: 'Emails'
        },
        {
            name: 'reminderTemplateId',
            label: 'Reminder Email Template',
            placeholder: '',
            type: 'select',
            required: true,
            dependsOn: {
                field: 'emailsEnabled',
                value: true
            },
            options: options.templates,
            tab: 'Emails'
        },
        {
            name: 'reminderThreshold',
            label: 'Reminder Email Threshold (Days)',
            placeholder: 'Number of days',
            type: 'text',
            required: true,
            dependsOn: {
                field: 'emailsEnabled',
                value: true
            },
            tab: 'Emails'
        }
    ];

    return (
        <BaseForm fields={fields}
            formState={calendar}
            setFormStateCallback={handleStateChange}
            submitButtonLabel="Save Calendar"
            validFormCallback={handleSubmit}
        />
    );
};

export default CalendarForm;
