import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { getLocationFields, getLocation, updateLocation } from '../data/location';
import BaseForm from '../components/base-form';
import { Alert } from 'react-bootstrap';
import TopNav from '../components/top-nav';

const LocationNewRoute = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const fields = getLocationFields().filter(item => item.showForm);
    const [showAlert, setShowAlert] = useState(false);
    const [variant, setVariant] = useState('');
    const [message, setMessage] = useState('');
    const [location, setLocation] = useState();

    const handleStateChange = (newLocation) => {
        setLocation(newLocation);
    };

    const handleSubmit = () => {
        const response = updateLocation(id, location);
        response.then((data) => {
            if (data.error) {
                setMessage(data.error.message);
                setVariant('danger');
                setShowAlert(true);
            } else {
                const state = {
                    message: 'The location has has been updated.',
                    variant: 'success'
                };
                navigate('/admin/locations', { state: state });
            }
        });
    };

    useEffect(() => {
        const response = getLocation(id);
        response.then(data => setLocation(data));
    }, []);

    if (!location) {
        return <>Please wait...</>;
    }

    const previousItems = [
        {
            label: 'Locations',
            link: '/admin/locations'
        }
    ];

    return (
        <>
            <TopNav activeItem="Edit Location" previousItems={previousItems} />
            <h1>Edit Location</h1>
            <Alert show={showAlert} variant={variant}>{message}</Alert>
            <BaseForm fields={fields} formState={location} setFormStateCallback={handleStateChange} submitButtonLabel="Save Location" validFormCallback={handleSubmit} />
        </>
    );
};

export default LocationNewRoute;
