import { useEffect, useState } from 'react';
import { useNavigate, useLocation, useOutletContext } from 'react-router-dom';
import { Alert, Button } from 'react-bootstrap';
import BaseList from '../components/base-list';
import { canManageCalendars, hasSystemRole } from '../data/user';
import { getCategoryFields, getCategories, deleteCategory } from '../data/category';
import TopNav from '../components/top-nav';

const CategoryListRoute = () => {
    const me = useOutletContext();
    const { state } = useLocation();
    const navigate = useNavigate();
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState('');
    const [variant, setVariant] = useState('');
    const [items, setItems] = useState([]);
    const fields = getCategoryFields().filter(item => item.showListing);

    const handleEdit = (item) => {
        navigate('/admin/categories/' + item['id'] + '/edit');
    };

    const handleDelete = (itemToDelete) => {
        const id = itemToDelete['id'];
        deleteCategory(id).then(data => {
            setVariant('success');
            setMessage('The category has been deleted.');
            setShowAlert(true);
            const newItems = items.filter(item => itemToDelete !== item);
            setItems(newItems);
        }).catch(e => {
            setVariant('danger');
            setMessage('The category could not be deleted. Make sure the category is not currently in use.');
            setShowAlert(true);
        });
    };

    useEffect(() => {
        getCategories().then(data => {
            setItems(data.items);
        }).catch(e => {
            setVariant('danger');
            setMessage(e.toString());
            setShowAlert(true);
        });

        if (state) {
            setMessage(state.message);
            setVariant(state.variant);
            setShowAlert(true);
            setTimeout(() => setShowAlert(false), 5000);
        }
    }, []);

    return (
        <>
            <TopNav activeItem="Categories" />
            <h1>Categories</h1>
            <Alert show={showAlert} variant={variant}>{message}</Alert>
          {variant !== 'danger' ? <BaseList fields={fields} items={items} handleEdit={handleEdit} handleDelete={hasSystemRole(me) ? handleDelete : null} /> : ''}
            {canManageCalendars(me) ? <Button onClick={() => navigate('/admin/categories/new')}>Add New Category</Button> : ''}
        </>
    );
};

export default CategoryListRoute;
