import { useState, useEffect, createRef } from 'react';
import { Button, Card, Col, Form, Row, Tab, Tabs } from 'react-bootstrap';
import FormField from './form-field';
import ReCAPTCHA from "react-google-recaptcha";

const BaseForm = ({
    colSize = 12,
    fields,
    formState = {},
    setFormStateCallback,
    readOnly = false,
    submitButtonLabel = 'Submit',
    validFormCallback,
    index = "",
    customSubmitElement = null,
    enableRecaptcha = false
}) => {
    const recaptchaRef = createRef();
    const [validated, setValidated] = useState(false);
    const handleChange = (field, newValue) => {
        setFormStateCallback({ ...formState, [field.name]: newValue });
    };

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);

        if (form.checkValidity()) {
            if (enableRecaptcha) {
                recaptchaRef.current.execute();
            } else {
                validFormCallback();
            }
        }
    };

    const onRecaptchaChange = (value) => {
        setFormStateCallback({ ...formState, recaptchaResponse: value });
    };

    function renderFormField(field, fieldIndex) {
        if (field.dependsOn) {
            if (formState[field.dependsOn.field] !== field.dependsOn.value) {
                return <></>;
            }
        }

        return (
            <Col xs={colSize}>
                <FormField
                    key={fieldIndex}
                    index={index}
                    field={field}
                    fieldValue={formState[field.name]}
                    readOnly={readOnly}
                    setFieldValue={(newValue) => handleChange(field, newValue)} />
            </Col>
        );
    };

    const renderTabs = (tabs = []) => {
        if (tabs.length < 1) {
            return <></>;
        }

        return (
            <Card className="mb-3">
                <Card.Body>
                    <Tabs justify defaultActiveKey="tab-0" className="mb-3">
                        {tabs.map((tab, index) =>
                            <Tab key={index} eventKey={"tab-" + index} title={tab}>
                                {fields.filter(item => item.tab === tab).map((field, fieldIndex) =>
                                    renderFormField(field, fieldIndex)
                                )}
                            </Tab>
                        )}
                    </Tabs>
                </Card.Body>
            </Card>
        );
    };

    const renderForm = () => {
        const tabs = [];

        fields.forEach(field => {
            if (field.tab && !tabs.includes(field.tab)) {
                tabs.push(field.tab);
            }
        });
        
        return (
            <Row>
                {fields.filter(item => !item.tab).map((field, fieldIndex) =>
                    renderFormField(field, fieldIndex)
                )}
                {renderTabs(tabs)}
                {enableRecaptcha ?
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={process.env.REACT_APP_RECAPTCHA}
                        size="invisible"
                        onChange={onRecaptchaChange}
                    /> : <></>}
            </Row>
        );
    };

    useEffect(() => {
        if (enableRecaptcha && formState.recaptchaResponse && validated) {
            validFormCallback();
        }
    }, [formState]);

    if (readOnly) {
        return renderForm();
    }

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          {renderForm()}
          {customSubmitElement ? customSubmitElement :
                <div className="d-grid gap-2 my-3">
                    <Button variant="primary" type="submit" size="lg">
                      {submitButtonLabel}
                    </Button>
                </div>
            }
        </Form>
    );
};

export default BaseForm;
