import { Breadcrumb, Button } from 'react-bootstrap';

const TopNav = ({ activeItem, previousItems = [], backLink = null }) => {
    return (
        <>
            <Breadcrumb className="d-print-none">
                <Breadcrumb.Item href="/admin">Calendar Overview</Breadcrumb.Item>
                {previousItems.map((x, i) =>
                    <Breadcrumb.Item key={i} href={x.link}>
                        {x.label}
                    </Breadcrumb.Item>
                )}
                {activeItem ? <Breadcrumb.Item active>{activeItem}</Breadcrumb.Item> : ''}
            </Breadcrumb>
            {backLink ? <Button href="#">Link</Button> : ''}
        </>
    );
};

export default TopNav;
