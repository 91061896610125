import { sendRequest } from './api';
import { convertToISO8601, toUrlParams } from './utils';
const BASE_URL = 'report';

export const getReport = (formState) => {
    const params = {
        ...formState,
        calendarIds: formState.calendarIds.join(','),
        startDate: convertToISO8601(formState.startDate),
        endDate: convertToISO8601(formState.endDate)
    };
    const url = BASE_URL + '?' + toUrlParams(params);

    return sendRequest(url);
}
