import { Card, Col } from "react-bootstrap";
import { useOutletContext } from "react-router";
import { t } from "../data/localization";
import { formatDate, formatTime } from "../data/utils";

const FrontendServiceStepDate = ({ header, calendarDates, selectedCalendarDate, setSelectedCalendarDate }) => {
    const locale = useOutletContext();
    const handleCalendarDateClick = (calendarDate) => {
        setSelectedCalendarDate(calendarDate);
        window.scrollTo({
            top: document.body.scrollHeight,
            left: 0,
            behavior: 'smooth',
        });
    };

    const renderTitle = (calendarDate) => {
        let title = '';

        if (calendarDate.sessionDates) {
            const dates = [calendarDate.date, ...calendarDate.sessionDates];
            title = <ul className="list-group list-group-flush">
                {dates.map((date, key) => <li key={key} className="list-group-item list-group-item-light">{formatDate(date)}</li>)}
            </ul>;
        } else {
            title = formatDate(calendarDate.date);
        }

        return title;
    };

    const renderText = (calendarDate) => {
        let text = '';

        if (calendarDate.sessionDates) {
            text += t('Available Times', locale) + ': ';
            text += calendarDate.timeslots.map(timeslot => formatTime(timeslot.time, locale)).join(',');
        }

        return text;
    };

    return (
        <>
            <p><strong>{header}</strong></p>
            {calendarDates.length > 0 ?
                calendarDates.map((calendarDate, index) => {
                    const isSelected = (selectedCalendarDate !== null && selectedCalendarDate === calendarDate);
                    return (
                        <Col key={index} className="mb-2">
                            <Card bg="light" role="button" border={isSelected ? 'primary' : ''}
                                onClick={() => handleCalendarDateClick(calendarDate)}>
                                <Card.Body>
                                    <Card.Title className="text-center">{renderTitle(calendarDate)}</Card.Title>
                                    <Card.Text>{renderText(calendarDate)}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    );
                })
                : <p>{t('There are no dates available.', locale)}</p>}
        </>
    );

};

export default FrontendServiceStepDate;
