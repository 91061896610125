import { useOutletContext } from "react-router";
import { useCallback, useEffect, useState } from "react";
import BaseForm from "./base-form";
import Loading from "./loading";
import { Parser } from "html-to-react";

const FrontendServiceStepForm = ({ form, calendarDateFilters, setCalendarDateFilters, setClientCount, callback }) => {
    const locale = useOutletContext();
    const [answers, setAnswers] = useState(null);
    const [currentQuestion, setCurrentQuestion] = useState(form.questions[0]);
    const [ending, setEnding] = useState(null);

    useEffect(() => {
        let defaultAnswers = {};
        form.questions.forEach(question => {
            defaultAnswers[question.name] = question.defaultValue;
        });
        setAnswers(defaultAnswers);
    }, []);

    useEffect(() => {
        if (ending !== null && ending.canContinue) {
            callback();
        }
    }, [ending]);

    const handleStateChange = useCallback((newState) => {
        setAnswers(newState);
    });

    const handleSubmit = useCallback(() => {
        let actions = [];

        currentQuestion.rules.forEach(rule => {
            const allMatched = rule.conditions.every(condition => {
                let isValid = true;
                const operand1 = answers[condition.questionName];
                const operand2 = condition.questionValue;

                switch (condition.operator) {
                case 'eq':
                    isValid = operand1 == operand2;
                    break;
                case 'gt':
                    isValid = operand1 > operand2;
                    break;
                case 'lt':
                    isValid = operand1 < operand2;
                    break;
                default:
                    isValid = false;
                }

                return isValid;
            });

            if (allMatched) {
                actions = [...actions, ...rule.actions];

                if (rule.stop) {
                    return;
                }
            }
        });

        actions.forEach(action => {
            switch (action.action) {
            case 'gotoEnding':
                setEnding(form.endings[action.actionValue]);
                break;
            case 'gotoQuestion':
                const newQuestion = form.questions.find(question =>
                    question.name === action.actionValue
                );
                setCurrentQuestion(newQuestion);
                break;
            case 'setClientCount':
                let clientCount = 1;

                if (action.actionValue.indexOf('[question') !== -1) {
                    clientCount = answers[action.actionValue.replace(/[\[\]']+/g,'')];
                } else {
                    clientCount = form.endings[action.actionValue];
                }

                setClientCount(clientCount);
                break;
            case 'setDuration':
                const duration = parseInt(action.actionValue);
                setCalendarDateFilters({
                    ...calendarDateFilters, duration: duration
                });
                break;
                    
            }
        });
    });

    if (currentQuestion === null || answers === null) {
        return <Loading />;
    }

    if (ending !== null) {
        return (
            <>
              <h2>{ending.header[locale]}</h2>
              {Parser().parse(ending.body[locale])}
            </>
        );
    }

    const fields = [
        {
            name: currentQuestion.name,
            type: currentQuestion.type,
            required: true,
            placeholder: currentQuestion.placeholder[locale],
            label: currentQuestion.label[locale],
            ...(currentQuestion.options) && {
                options: currentQuestion.options.map(option => {
                    return {
                        value: option.value,
                        label: option.label[locale]
                    };
                })
            }
        }
    ];

    return (
        <>
            <BaseForm
                fields={fields}
                formState={answers}
                setFormStateCallback={handleStateChange}
                submitButtonLabel="Next"
                validFormCallback={handleSubmit}
            />
        </>
    );

};

export default FrontendServiceStepForm;
