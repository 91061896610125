import { useState } from 'react';
import { useNavigate } from 'react-router';
import { Container, Col, Row, Button, Alert } from 'react-bootstrap';
import BaseForm from '../components/base-form';
import Footer from '../components/footer';
import { sendRequest } from '../data/api';

const Login = () => {
    const navigate = useNavigate();
    const [isBusy, setIsBusy] = useState(false);
    const [variant, setVariant] = useState('');
    const [message, setMessage] = useState('');
    const [formState, setFormState] = useState({
        username: null,
        password: null
    });
    const [fields, setFields] = useState([
        {
            name: 'username',
            label: 'Email Address',
            placeholder: 'Enter your email address',
            type: 'email',
            required: true
        },
        {
            name: 'password',
            label: 'Password',
            placeholder: 'Enter your password',
            type: 'password',
            required: true
        }
    ]);

    const handleSubmitEvent = () => {
        if (!isBusy) {
            setIsBusy(true);
            sendRequest(
                'login_check',
                'POST',
                formState
            ).then((response) => {
                window.localStorage.setItem('auth', JSON.stringify(response));
                navigate('/admin');
            }).catch(e => {
                if (e.toString() === 'ACCOUNT_DISABLED') {
                    setMessage('This account is currently disabled. Please contact your supervisor.');
                    setVariant('warning');
                } else if (e.toString() === '2FA_REQUIRED') {
                    setFormState({ ...formState, twoFactorCode: '' });
                    setFields([...fields, {
                        name: 'twoFactorCode',
                        label: '2FA Code',
                        placeholder: 'Enter six-digit code',
                        minLength: 6,
                        maxLength: 6,
                        pattern: '^[0-9]{1,6}$',
                        type: 'text',
                        required: true
                    }]);
                    setMessage('Please enter the six-digit code that was sent to your email address.');
                    setVariant('info');
                } else if (e.toString() === '2FA_FAILED') {
                    setMessage('The code you entered is invalid.');
                    setVariant('danger');
                } else {
                    setMessage(e.toString());
                    setVariant('danger');
                }

                setIsBusy(false);
            });
        }
    };

    return (
        <>
            <main role="main">
                <Container fluid>
                    <Row className="d-flex flex-column min-vh-100 justify-content-center align-items-center login-form-container">
                        <>
                            <Col lg="4">
                                <img className="mx-auto d-block" src="/logo.png" alt="EOHU Logo" title="EOHU Logo" />
                                <Alert className="my-4 text-center" show={message !== ''} variant={variant}>{message}</Alert>
                                <BaseForm
                                    enableRecaptcha={false}
                                    fields={fields}
                                    formState={formState}
                                    setFormStateCallback={(x) => setFormState(x)}
                                    submitButtonLabel={'Login'}
                                    validFormCallback={handleSubmitEvent} />
                                <p className="text-center">
                                    <a href="/admin/password-reset-request">Reset my password</a>
                                </p>
                            </Col>
                        </>
                    </Row>
                </Container>
            </main>
            <Footer />
        </>
    );
};

export default Login;
