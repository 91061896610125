import { sendRequest } from './api';

export const getCategoryFields = () => {
    return [
        {
            name: 'name',
            label: 'Name',
            placeholder: 'Enter category name',
            type: 'text',
            required: true,
            showListing: true,
            showForm: true
        },
        {
            name: 'isDefault',
            label: 'Default',
            type: 'checkbox',
            required: false,
            showListing: true,
            showForm: true
        },
        {
            name: 'createdAt',
            label: 'Created At',
            placeholder: '',
            type: 'datetime',
            required: false,
            showListing: true,
            showForm: false
        },
        {
            name: 'updatedAt',
            label: 'Updated At',
            placeholder: '',
            type: 'datetime',
            required: false,
            showListing: true,
            showForm: false
        }
    ];
};

export const getDefaultCategoryState = () => {
    return {
        name: '',
        isDefault: false
    };
};

export const getCategories = () => {
    const response = sendRequest('categories');
    return response;
}

export const getCategory = (id) => {
    const response = sendRequest('categories/' + id);
    return response;
};

export const createCategory = (category) => {
    return sendRequest('categories', 'POST', category);
};

export const updateCategory = (id, category) => {
    return sendRequest('categories/' + id, 'PUT', category);
};

export const deleteCategory = (id) => {
    return sendRequest('categories/' + id, 'DELETE');
};
