import { Ratio, Row } from 'react-bootstrap';
import TopNav from '../components/top-nav';

const HelpRoute = () => {
    return (
        <>
            <TopNav />
            <h1>Help</h1>
            <h2 className="my-4">Table of Contents</h2>
            <ul>
                <li><a href="#01-new-calendar">Creating a New Calendar</a></li>
                <li><a href="#02-new-dates">Creating Calendar Dates</a></li>
                <li><a href="#03-editing-dates">Editing Calendar Dates</a></li>
                <li><a href="#04-moving-dates">Moving Calendar Dates</a></li>
                <li><a href="#05-copying-dates">Copying Calendar Dates</a></li>
                <li><a href="#06-calendar-access">Managing Calendar Access</a></li>
            </ul>
            <h3 className="my-4" id="01-new-calendar">Creating a New Calendar</h3>
            <video width="50%" controls>
                <source src="/videos/01-new-calendar.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <h3 className="my-4" id="02-new-dates">Creating Calendar Dates</h3>
            <video width="50%" controls>
                <source src="/videos/02-new-dates.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <h3 className="my-4" id="03-editing-dates">Editing Calendar Dates</h3>
            <video width="50%" controls>
                <source src="/videos/03-editing-dates.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <h3 className="my-4" id="04-moving-dates">Moving Calendar Dates</h3>
            <video width="50%" controls>
                <source src="/videos/04-moving-dates.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <h3 className="my-4" id="05-copying-dates">Copying Calendar Dates</h3>
            <video width="50%" controls>
                <source src="/videos/05-copying-dates.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <h3 className="my-4" id="06-calendar-access">Managing Calendar Access</h3>
            <video width="50%" controls>
                <source src="/videos/06-calendar-access.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </>
    );
};

export default HelpRoute;
