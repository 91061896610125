import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Outlet } from "react-router-dom";
import { Container, Col, Row, Alert } from 'react-bootstrap';
import Header from "./components/header";
import Footer from './components/footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { getCurrentUser } from './data/me';
import Loading from './components/loading';

const App = () => {
     const navigate = useNavigate();
    const [me, setMe] = useState();
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState(null);
    const [variant, setVariant] = useState(null);

    useEffect(() => {
        getCurrentUser('/me').then(res => {
            setMe(res);
        }).catch(e => {
            if (e.toString() === 'Unauthorized') {
                navigate('/admin/login');
            } else {
                setMessage(e.toString());
                setVariant('danger');
                setShowAlert(true);
            }
        });
    }, []);

    if (!me) {
        return <Loading/>;
    }

    return (
        <>
            <Header me={me} />
            <Alert show={showAlert} variant={variant}>{message}</Alert>
            <main role="main">
                <Container fluid className="px-5 py-5">
                    <Row>
                        <Col>
                            <Outlet context={me} />
                        </Col>
                    </Row>
                </Container>
            </main>
            <Footer />
        </>
    );
}

export default App;
