import { sendRequest } from './api';
import { convertToISO8601 } from './utils';
const BASE_URL = 'dashboard';

export const getDashboard = (filter) => {
    const firstDate = new Date(filter.year, filter.month);
    const lastDate = new Date(filter.year, filter.month + 1);
    const params = {
        startDate: convertToISO8601(firstDate),
        endDate: convertToISO8601(lastDate),
        categoryId: filter.categoryId,
        region: filter.region
    };
    const url = BASE_URL + '?' + new URLSearchParams(params).toString();
    const response = sendRequest(url);

    return response;
};
